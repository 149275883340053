// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid2,
  IconButton,
  Input,
  InputLabel,
  lighten,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const newsletterArticles = [
  {
    id: 1,
    title:
      "COA Administrators’ Network and Community Oncology Pharmacy Association Announce New Advisory Board Members",
    featuredImage: "https://assets.mycoa.io/1739460614681_press-release.jpg",
    link: "https://mycoa.communityoncology.org/news-updates/press-releases/can-and-copa-announce-new-advisory-board-members-2025",
  },
  {
    id: 2,
    title:
      "Congress Must Act Now to Avert Physician Pay Cuts and Start Major Action to Curb PBM Abuses",
    featuredImage: "https://assets.mycoa.io/1739460614681_press-release.jpg",
    link: "https://mycoa.communityoncology.org/news-updates/press-releases/2024-CR-Ted-Okon-statement",
  },
  {
    id: 3,
    title: "COA Elects New President, Officers, and Board Members",
    featuredImage: "https://assets.mycoa.io/1739460614681_press-release.jpg",
    link: "https://mycoa.communityoncology.org/news-updates/press-releases/coa-elects-new-president-officers-and-board-members-2025",
  },
  {
    id: 4,
    title: "COA Launches Two Patient Advocacy Network Chapters in Washington, D.C. Area",
    featuredImage: "https://assets.mycoa.io/1739460614681_press-release.jpg",
    link: "https://mycoa.communityoncology.org/news-updates/press-releases/coa-launches-two-patient-advocacy-network-chapters-in-washington-dc-area",
  },
];

const SinglePageDemo = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bookmark, setBookmark] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <PageWrapper sx={{ py: 6 }}>
      <div className="article-wrapper px-3">
        <div className="article-left"></div>
        <article className="article-content">
          <header>
            <h1>
              COA Supports New Bipartisan Senate Legislation to Hold PBMs Accountable, Protect
              Cancer Patients
            </h1>
            <Typography className="desc">
              <em>
                Yesterday, U.S. Senators Chuck Grassley (R-IA) and Maria Cantwell (D-WA)
                reintroduced two key bills aimed at increasing transparency and oversight of
                pharmacy benefit managers (PBMs)
              </em>
            </Typography>
            <hr />
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"5px 15px"}
              flexWrap={"wrap"}
              pt={1}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"5px 15px"}
                flexWrap={"wrap"}
                sx={{ mr: "auto" }}
              >
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center", gap: "3px" }}
                  fontSize={"14px"}
                  color={"grey"}
                  fontWeight={600}
                >
                  <Icon icon="bx:calendar" />
                  Feb 13, 2025
                </Typography>
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center", gap: "3px" }}
                  fontSize={"14px"}
                  color={"grey"}
                  fontWeight={600}
                >
                  <Icon icon="tabler:category" />
                  Events : Advocacy Chats
                </Typography>
              </Stack>

              <GreyButton
                size="small"
                variant="outlined"
                className={bookmark ? "bookmarked" : ""}
                onClick={() => setBookmark(!bookmark)}
                sx={{
                  borderRadius: "100px",
                  minWidth: "unset",
                  maxWidth: "36px",
                  height: "36px",
                  p: 0,
                  width: "100%",
                }}
              >
                <Icon icon="bx:bookmark" width={22} />
              </GreyButton>

              <GreyButton
                id="share-article"
                size="small"
                variant="outlined"
                sx={{
                  borderRadius: "100px",
                  minWidth: "unset",
                  maxWidth: "36px",
                  height: "36px",
                  p: 0,
                  width: "100%",
                }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Icon icon="bx:share-alt" width={22} />
              </GreyButton>

              <Menu
                id="share-article"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon size="small">
                    <Icon icon="devicon-plain:facebook" width={"24px"} />
                  </ListItemIcon>
                  <ListItemText sx={{ ".MuiTypography-root": { fontSize: "14px" } }}>
                    Facebook
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Icon icon="fa6-brands:square-x-twitter" width={"23px"} />
                  </ListItemIcon>
                  <ListItemText sx={{ ".MuiTypography-root": { fontSize: "14px" } }}>
                    Twitter
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Icon icon="f7:logo-instagram" width={"28px"} />
                  </ListItemIcon>
                  <ListItemText sx={{ ".MuiTypography-root": { fontSize: "14px" } }}>
                    Instagram
                  </ListItemText>
                </MenuItem>
              </Menu>
              <Button
                size="sm"
                variant="contained"
                component={"a"}
                href="#"
                target="_blank"
                color="primary"
                sx={{ borderRadius: "100px", "&:hover": { color: "#fff" } }}
                startIcon={<Icon icon="bx:download" fontSize={"18px"} />}
              >
                Download
              </Button>
            </Stack>
          </header>

          {/* Article body starts */}
          <Box className="article-body">
            <img
              alt="featured"
              className="featured-image"
              src="https://assets.mycoa.io/1739460614681_press-release.jpg"
              style={{ marginTop: "25px" }}
            />

            <p>
              Yesterday, U.S. Senators Chuck Grassley (R-IA) and Maria Cantwell (D-WA) reintroduced
              two key bills aimed at increasing transparency and oversight of pharmacy benefit
              managers (PBMs): the Prescription Pricing for the People Act (S.527) and the Pharmacy
              Benefit Manager Transparency Act (S.526). These bipartisan bills seek to lower
              prescription drug costs and curb abusive PBM practices that harm patients. The
              Community Oncology Alliance (COA) strongly supports these bills and stands ready to
              help advance them.
            </p>

            <p>
              “COA commends Senators Grassley and Cantwell for once again taking an early lead in
              introducing pharmacy benefit manager (PBM) legislation in the 119th Congress,” said
              Ted Okon, executive director of COA. “The Prescription Pricing for the People Act and
              the PBM Transparency Act will bring long-overdue scrutiny and accountability to the
              PBM industry, whose unchecked practices drive up costs and create dangerous barriers
              to care for patients with cancer and other serious diseases. These bills lit the fuse
              for additional legislative efforts in both the Senate and House last Congress to rein
              in the abuses of the dominant PBMs, and we thank Senators Grassley and Cantwell for
              reintroducing these critical measures.”
            </p>

            <p>
              “Americans face medication delays and denials, as well as higher costs and waste, at
              the hands of the top PBMs—especially CVS/Caremark, Cigna/Express Scripts, and
              United/Optum Rx—which control 80 percent of the prescription drug market,” Okon
              continued. “They must be stopped from harming patients with cancer and others with
              serious illnesses. It’s time for Congress to act now.”
            </p>

            <p>
              Last week, COA released its Prescription for Health Care Reform, a comprehensive
              policy roadmap that details recommendations to fix our broken health care system. The
              Prescription underscores the urgent need for legislative action on PBMs and other
              critical health care reforms. Learn more at{" "}
              <a href="https://mycoa.communityoncology.org/education-publications/report/coa-prescription">
                https://mycoa.communityoncology.org/education-publications/report/coa-prescription
              </a>
              .
            </p>

            <Divider sx={{ mb: 2, mt: 2 }} />

            <small>
              <h5>About the Community Oncology Alliance</h5>
              <p>
                <a href="https://communityoncology.org/">
                  <span>The Community Oncology Alliance (COA)</span>
                </a>
                <i>
                  is a nonprofit organization dedicated to advocating for community oncology
                  practices and, most importantly, the patients they serve. COA is the only
                  organization dedicated solely to community oncology where the majority of
                  Americans with cancer are treated. The mission of COA is to ensure that patients
                  with cancer receive quality, affordable, and accessible cancer care in their own
                  communities. Learn more about COA at
                </i>
                <a href="http://www.communityoncology.org/">
                  <span>www.communityoncology.org</span>
                </a>
                <em>.</em>
              </p>
            </small>
            <Divider sx={{ mt: 2, mb: 2.5 }} />
            <Box>
              <small>See more: </small>
              <small style={{ fontStyle: "italic" }}>
                <a href="https://mycoa.communityoncology.org/news-updates/press-releases/">
                  Press Release
                </a>
              </small>

              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"5px 15px"}
                flexWrap={"wrap"}
                mt={2}
              >
                <GreyButton
                  size="small"
                  variant="outlined"
                  className={bookmark ? "bookmarked" : ""}
                  onClick={() => setBookmark(!bookmark)}
                  sx={{
                    borderRadius: "100px",
                    minWidth: "unset",
                    maxWidth: "36px",
                    height: "36px",
                    p: 0,
                    width: "100%",
                  }}
                >
                  <Icon icon="bx:bookmark" width={22} />
                </GreyButton>

                <GreyButton
                  id="share-article"
                  size="small"
                  variant="outlined"
                  sx={{
                    borderRadius: "100px",
                    minWidth: "unset",
                    maxWidth: "36px",
                    height: "36px",
                    p: 0,
                    width: "100%",
                  }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Icon icon="bx:share-alt" width={22} />
                </GreyButton>

                <Button
                  size="sm"
                  variant="contained"
                  component={"a"}
                  href="#"
                  target="_blank"
                  color="primary"
                  sx={{ borderRadius: "100px", "&:hover": { color: "#fff" } }}
                  startIcon={<Icon icon="bx:download" fontSize={"18px"} />}
                >
                  Download
                </Button>
              </Stack>
            </Box>
          </Box>
        </article>
        <Box className="article-right"></Box>
      </div>
      {/*End: article wrapper */}

      <Box
        sx={{
          backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.9),
          mt: 6,
          pt: 6,
          pb: 6,
        }}
      >
        <Container>
          <Grid2 container spacing={4} alignItems={"center"}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Card
                elevation={0}
                sx={{
                  display: "flex",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  gap: [0, 2],
                  alignItems: "center",
                  mt: 1,
                  mb: 3,
                  backgroundColor: "transparent",
                }}
              >
                <CardMedia
                  sx={{
                    maxWidth: {
                      //   xs: "100%",
                      //   sm: "100px",
                      img: {
                        width: "84px",
                        height: "84px",
                        objectFit: "cover",
                      },
                    },
                  }}
                >
                  <img src="/coa.svg" alt="featured" />
                </CardMedia>
                <CardContent sx={{ p: 0, pb: "0 !important" }}>
                  <Typography fontWeight={800} component={"h3"} fontSize={"24px"}>
                    Stay Connected
                  </Typography>
                  <Typography fontSize={"1.0625rem"} fontWeight={500}>
                    The latest community oncology news & updates from COA direct to your inbox. We
                    respect your privacy.
                  </Typography>
                </CardContent>
              </Card>

              <form>
                <Grid2
                  container
                  spacing={2}
                  sx={{ input: { backgroundColor: "#fff", borderRadius: 1 } }}
                >
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField
                      placeholder="First Name"
                      fullWidth
                      name="first-name"
                      id="first-name"
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField fullWidth placeholder="Last Name" name="last-name" id="last-name" />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField placeholder="Email" fullWidth name="email" id="email" />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField fullWidth placeholder="Zip Code" name="zip-code" id="zip-code" />
                  </Grid2>
                </Grid2>
                {/* <Alert variant="filled" severity="success" sx={{ mt: 2 }}>
                  Form submitted successfully
                </Alert> */}
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ px: 3, py: 1.5, textTransform: "uppercase", mt: 2 }}
                >
                  Submit
                </Button>
              </form>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img src="/images/stay-connected.jpg" alt="stay connected" width={"100%"} />
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      {/* Begin: Interpreter */}
      <Box sx={{ pt: 6 }}>
        <Container>
          {/* Recent */}
          <Typography component={"h5"} fontWeight={600} mb={3}>
            Related <a href="#">[More]</a>
          </Typography>

          <Grid2 container flexWrap={"wrap"} spacing={3}>
            {newsletterArticles.map((item, index) => {
              return (
                <Grid2 key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Card
                    elevation={0}
                    component={"a"}
                    href={item.link}
                    sx={{
                      display: "block",
                      transition: "300ms ease",
                      "&:hover": { transform: "translateY(-3px)" },
                    }}
                  >
                    <CardMedia
                      component={"img"}
                      image={item.featuredImage}
                      alt="featured"
                      sx={{ "&": { aspectRatio: "16/9" } }}
                    />
                    <CardContent sx={{ p: "0 !important" }}>
                      <Typography mt={2} fontWeight={500}>
                        {item.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid2>
              );
            })}
          </Grid2>

          {/* Recent */}

          <Divider sx={{ mt: 4, mb: 3.5 }} />
          <Typography component={"h5"} fontWeight={600} mb={3}>
            Featured from COA <a href="#">[More]</a>
          </Typography>

          <Grid2 container flexWrap={"wrap"} spacing={3}>
            {newsletterArticles.map((item, index) => {
              return (
                <Grid2 key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Card
                    elevation={0}
                    component={"a"}
                    href={item.link}
                    sx={{
                      display: "block",
                      transition: "300ms ease",
                      "&:hover": { transform: "translateY(-3px)" },
                    }}
                  >
                    <CardMedia
                      component={"img"}
                      image={item.featuredImage}
                      alt="featured"
                      sx={{ "&": { aspectRatio: "16/9" } }}
                    />
                    <CardContent sx={{ p: "0 !important" }}>
                      <Typography mt={2} fontWeight={500}>
                        {item.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid2>
              );
            })}
          </Grid2>

          <Divider sx={{ mt: 4, mb: 3.5 }} />
          <Typography component={"h5"} fontWeight={600} mb={3}>
            Press Releases <a href="#">[More]</a>
          </Typography>

          <Grid2 container flexWrap={"wrap"} spacing={3}>
            {newsletterArticles.map((item, index) => {
              return (
                <Grid2 key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Card
                    elevation={0}
                    component={"a"}
                    href={item.link}
                    sx={{
                      display: "block",
                      transition: "300ms ease",
                      "&:hover": { transform: "translateY(-3px)" },
                    }}
                  >
                    <CardMedia
                      component={"img"}
                      image={item.featuredImage}
                      alt="featured"
                      sx={{ "&": { aspectRatio: "16/9" } }}
                    />
                    <CardContent sx={{ p: "0 !important" }}>
                      <Typography mt={2} fontWeight={500}>
                        {item.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid2>
              );
            })}
          </Grid2>
        </Container>
      </Box>
    </PageWrapper>
  );
};

export default SinglePageDemo;

const PageWrapper = styled(Box)(({ theme }) => ({
  background: "linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 15%);",
  ".article-wrapper": {
    display: "grid",
    gridTemplateColumns: "1fr minmax(auto, 600px) 1fr",
    gridTemplateRows: "1fr 400px 1fr",
    gap: "40px",

    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto 800px auto",
      gridTemplateRows: "auto",
      justifyContent: "center",
    },

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gap: "10px",

      ".article-left, .article-right": {
        display: "none",
      },
    },

    [theme.breakpoints.up("lg")]: {
      ".article-left": {
        gridRow: "1/4",
      },
      ".article-content": {
        gridRow: "1/4",
      },
      ".article-right": {
        gridRow: "1/4",
      },
    },
  },

  ".three-line-desc p, .three-line-desc": {
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  },

  ".article-content": {
    "--articleBodyFontsize": "1.0625em",
    "--articleBodyLineHeight": "1.9",
    "--articleHeadingLineHeight": "1.4",

    "@media(max-width: 576px)": {
      "--articleBodyFontsize": "1rem",
      "--articleBodyLineHeight": "1.8",
    },

    ".featured-image": {
      aspectRatio: "4/2",
      objectFit: "cover",
    },

    // Image Styling
    img: {
      width: "100%",
      display: "block",
      marginBlock: "30px",
      borderRadius: "3px",
    },

    // Headings
    h1: {
      fontSize: "1.9rem",
      marginBottom: "25px",
      fontWeight: "700",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h2: {
      fontSize: "1.7rem",
      marginTop: "25px",
      marginBottom: "15px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h3: {
      fontSize: "1.5rem",
      marginTop: "25px",
      marginBottom: "12px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h4: {
      fontSize: "1.3rem",
      marginTop: "25px",
      marginBottom: "10px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h5: {
      fontSize: "1.1rem",
      marginTop: "25px",
      marginBottom: "8px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h6: {
      fontSize: "1rem",
      marginTop: "25px",
      marginBottom: "6px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },

    p: {
      fontSize: "var(--articleBodyFontsize)",
      // fontWeight: 500,
      lineHeight: "var(--articleBodyLineHeight)",
      marginBottom: "25px",
    },

    "a:not(.MuiButtonBase-root)": {
      wordBreak: "break-all",
      fontSize: "var(--articleBodyFontsize)",
      lineHeight: "var(--articleBodyLineHeight)",
    },

    em: {
      fontWeight: 600,
    },

    small: {
      // fontWeight: 500,
    },

    li: {
      fontSize: "var(--articleBodyFontsize)",
      lineHeight: "var(--articleBodyLineHeight)",
      // fontWeight: 500,
    },

    // Blockquote
    blockquote: {
      fontStyle: "italic",
      borderLeft: "4px solid var(--primary)",
      lineHeight: "var(--articleBodyLineHeight)",
      paddingLeft: "15px",
      margin: "25px 0",
      color: "#555",
      fontSize: "var(--articleBodyFontsize)",
      // fontWeight: 500,
    },

    // Tables
    table: {
      width: "100%",
      borderCollapse: "collapse",
      margin: "25px 0",
    },
    "table, th, td": {
      border: "1px solid #ddd",
    },
    th: {
      backgroundColor: "#f4f4f4",
      padding: "10px",
      textAlign: "left",
      fontSize: "var(--articleBodyFontsize)",
      lineHeight: "var(--articleBodyLineHeight)",
      fontWeight: 600,
    },
    td: {
      padding: "10px",
      textAlign: "left",
      fontSize: "var(--articleBodyFontsize)",
      // fontWeight: 500,
      lineHeight: "var(--articleBodyLineHeight)",
    },

    // Embedded Videos
    iframe: {
      display: "block",
      width: "100%",
      height: "350px",
      margin: "25px 0",
    },
  },
}));

const GreyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[700], // Text color
  borderColor: theme.palette.grey[300], // Border color

  "&:hover": {
    backgroundColor: theme.palette.grey[50], // Hover background
    borderColor: theme.palette.grey[400],
  },

  "&.bookmarked": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));
