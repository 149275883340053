import React, { useCallback, useEffect, useState } from "react"
import { TabContent, TabPane, Nav, NavItem, Container } from "reactstrap"
import classnames from "classnames"
import styled from "styled-components"
import SubHeader from "../../components/SubHeader"
import {
  ResultTopBar,
  SearchBoxWrapper,
  SortByWrapper,
  SearchResults,
  ClearSearchButton,
  CategoryWrapper,
} from "../post/post"
import {
  InstantSearch,
  SearchBox,
  SortBy,
  Stats,
  Pagination,
  Configure,
  connectStateResults,
  connectSearchBox,
  ClearRefinements,
  RefinementList,
  Panel,
} from "react-instantsearch-dom"
import { Card, Col, Row } from "reactstrap"
import algoliasearch from "algoliasearch/lite"
import EventItem from "../../components/EventCalendar/EventItem"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import PostTeaser from "../../components/post-teaser/PostTeaser"
import featuredImage from "../../assets/images/about-image.jpg"
import { Box, Grid, Grid2 } from "@mui/material"

const searchClient = algoliasearch("HGGCT1WR6X", "6ba4dbf1d041e3c8976252f142ffd6bd")

const CustomSearchBox = ({ currentRefinement, refine }) => {
  return (
    <input
      type="search"
      className="hidden"
      value={currentRefinement}
      onChange={(e) => refine(e.target.value)}
      placeholder="Search"
    />
  )
}

const ConnectedCustomSearchBox = connectSearchBox(CustomSearchBox)

const EventList = () => {
  const [activeTab, setActiveTab] = useState("2")
  const [userMetadata, setUserMetadata] = useState(null)
  const [showTeaser, setShowTeaser] = useState(false)

  const { user, isAuthenticated } = useAuth0()

  const favoriteEvents = userMetadata?.favorite_events || []
  const [slugVal, setSlugVal] = useState("")

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const groupEventsByMonthYear = (events) => {
    return events.reduce((acc, event) => {
      const eventDate = new Date(event?.event_data?.start_date ? event.event_data.start_date : null)
      const monthYear = eventDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      })
      if (!acc[monthYear]) acc[monthYear] = []
      acc[monthYear].push(event)
      return acc
    }, {})
  }

  const now = Math.floor(new Date().getTime() / 1000) // Unix timestamp in seconds

  let filterString = `type:Event`

  let label = ""

  if (activeTab === "1") {
    // label = "Featured Events"
  } else if (activeTab === "2") {
    // Upcoming
    filterString += ` AND event_data.start_timestamp >= ${now}`
    // label = "Upcoming"
  } else if (activeTab === "3") {
    // Past
    filterString += ` AND event_data.start_timestamp < ${now}`
    // label = "Past Events"
  } else if (activeTab === "4") {
    // label = ""
  }

  const toggleFavoriteEvent = async (eventId) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/resource/make-favourite", {
        user_id: user.sub.replace("auth0|", ""),
        resource_id: eventId,
        type: favoriteEvents.includes(eventId) ? "Remove" : "Add",
      })

      const updatedUserMetadata = {
        ...user.user_metadata,
        favorite_events: favoriteEvents.includes(eventId)
          ? favoriteEvents.filter((id) => id !== eventId)
          : [...favoriteEvents, eventId],
      }

      setUserMetadata(updatedUserMetadata)

      return response.data
    } catch (error) {
      console.error("Error toggling favorite event:", error.response?.data || error.message)
      throw error
    }
  }

  useEffect(() => {
    if (user) {
      setUserMetadata(user.user_metadata)
    }
  }, [user])
  return (
    <EventListWrapper className="article-category">
      {/* <SubHeader title="Events" /> */}
      <InstantSearch searchClient={searchClient} indexName="pro_mycoa_event">
        <div className="pt-6 pb-6">
          <Container>
            <Grid2 container spacing={{ xs: 4, md: 10 }}>
              <Grid2 item size={{ xs: 12, md: 6 }} sx={{ textAlign: "center" }}>
                <Box
                  component={"img"}
                  src={"https://communityoncology.org/wp-content/uploads/2023/01/mycoa-image-11.png"}
                  alt="Featured event"
                  sx={{ width: { xs: "100%", md: "80%" } }}
                />
              </Grid2>
              <Grid2 item size={{ xs: 12, md: 6 }} sx={{ alignSelf: "center" }}>
                <Box sx={{ width: { xs: "100%", md: "65%" } }}>
                  <h1>Events</h1>
                  <p>
                    COA delivers unparalleled advocacy, valuable education, and exceptional networking opportunities to
                    support independent community oncology.
                  </p>
                </Box>
              </Grid2>
            </Grid2>
          </Container>
        </div>

        <Box>
          <img src="/images/event-banner.jpg" alt="event-banner" width={"100%"} />
        </Box>
        <Container>
                  <Row className="pt-6 pb-5">
          <Col lg={3} className="mb-5 mb-lg-0">
            <Facets slugSearch={slugVal} />
          </Col>
          <Col lg={9} >
            <Card className=" border-0" style={{background:'transparent'}}>
              <Nav tabs className="pb-4" style={{ gap: "5px 15px" }}>
                <NavItem className="mb-0">
                  <TabButton className={classnames({ active: activeTab === "2" })} onClick={() => toggle("2")}>
                    Upcoming
                  </TabButton>
                </NavItem>
                <NavItem className="mb-0">
                  <TabButton className={classnames({ active: activeTab === "3" })} onClick={() => toggle("3")}>
                    Past
                  </TabButton>
                </NavItem>
                <NavItem className="mb-0">
                  <TabButton className={classnames({ active: activeTab === "1" })} onClick={() => toggle("1")}>
                    All
                  </TabButton>
                </NavItem>
                {isAuthenticated && (
                  <NavItem className="mb-0">
                    <TabButton className={classnames({ active: activeTab === "4" })} onClick={() => toggle("4")}>
                      My Favorites
                    </TabButton>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={activeTab} className="mt-4">
                <TabPane tabId={activeTab}>
                  <div className="search-inner">
                    <ConnectedCustomSearchBox />
                    <Configure hitsPerPage={16} filters={filterString} />

                    <Row>
                      <Col lg={"12"}>
                        <SearchBoxWrapper className={"contained"}>
                          <SearchBox translations={{ placeholder: "Search for an event" }} className="search-box" />
                          <div
                            className="d-block d-sm-flex align-items-center justify-content-between flex-wrap"
                            style={{ gap: "15px" }}
                          >
                            <ResultTopBar className="results-topbar">
                              <div className="text-warning flex-grow py-30 d-flex align-items-center">
                                <Stats />
                              </div>
                            </ResultTopBar>
                            {/* <SortByWrapper className="sort-by d-block d-sm-flex justify-content-end mb-sm-0">
                            <label className="sr-only">Sort by</label>
                            <SortBy
                              items={[
                                { value: "pro_mycoa_event", label: "Latest" },
                                // { value: "prod_myCOA_sortBy_dateASC", label: "Oldest" },
                              ]}
                              defaultRefinement="pro_mycoa_event"
                            />
                          </SortByWrapper> */}
                          </div>
                        </SearchBoxWrapper>
                        <h4 className="font-weight-bold mt-4 mb-0">{label}</h4>
                        <CustomResults
                          groupEventsByMonthYear={groupEventsByMonthYear}
                          favoriteEvents={favoriteEvents}
                          activeTab={activeTab}
                          toggleFavoriteEvent={toggleFavoriteEvent}
                          setShowTeaser={setShowTeaser}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </TabContent>
            </Card>

            {showTeaser ? <PostTeaser closable={setShowTeaser} /> : ""}
          </Col>
        </Row>
        </Container>
      </InstantSearch>
    </EventListWrapper>
  )
}

export default EventList

const EventListWrapper = styled.div`
  .grouped-month-year {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #000000;
  }

  .MuiPagination-root ul li {
    margin-bottom: 0px !important;
  }

  background: linear-gradient(to bottom, #d5e5ef 10%, #fff 35%, #fff 55%);
  position: relative;
  top: -2px;

  .inner-wrapper {
    padding: 30px;
    padding-top: 54px;
    padding-bottom: 0;
    border-radius: 30px;
  }

  .hidden {
    display: none;
  }

  .ais-Highlight {
    .ais-Highlight-highlighted {
      background-color: yellow;
      font-style: normal;
    }
  }
`

export const TabButton = styled.div`
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
  transition: background-color 300ms ease;
  cursor: pointer;

  &:not(.active) {
    color: #7d7d7d;
  }

  &:hover {
    background-color: #f2f2f2;
  }

  &.active {
    background-color: #f2f2f2;
    font-weight: 600;
  }
`

const Hit = ({ hit, favoriteEvents, toggleFavoriteEvent, setShowTeaser }) => (
  <EventItem
    event={hit}
    favoriteEvents={favoriteEvents}
    toggleFavoriteEvent={toggleFavoriteEvent}
    setShowTeaser={setShowTeaser}
  />
)

const CustomResults = connectStateResults(
  ({ searchResults, groupEventsByMonthYear, favoriteEvents, activeTab, toggleFavoriteEvent, setShowTeaser }) => {
    if (!searchResults || !searchResults.nbHits) {
      return (
        <div className="no-results pb-5 ">
          No results found matching <span className="query">{searchResults?.query}</span>
        </div>
      )
    }

    let filteredResults = searchResults.hits

    if (activeTab === "4") {
      filteredResults = filteredResults.filter((event) => favoriteEvents.includes(event._id))
    }

    const groupedEvents = groupEventsByMonthYear(filteredResults)

    return (
      <div className="results-wrapper p-0">
        {/* <hr className="mb-5 mt-5 border-primary" /> */}
        <SearchResults>
          {Object.entries(groupedEvents).map(([monthYear, events]) => (
            <div key={monthYear}>
              <h5 className="grouped-month-year">{monthYear}</h5>
              {events.map((event) => (
                <Hit
                  key={event._id}
                  hit={event}
                  favoriteEvents={favoriteEvents}
                  toggleFavoriteEvent={toggleFavoriteEvent}
                  setShowTeaser={setShowTeaser}
                />
              ))}
            </div>
          ))}
          <footer>
            <Pagination showLast={true} />
          </footer>
        </SearchResults>
      </div>
    )
  }
)

const transformItems = (items) => {
  return items.map((item) => {
    let label = item.label.replace(/-/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())
    if (item.label === "em-coding-billing") {
      label = "E&M Coding Billing"
    } else if (item.label === "education-publications") {
      label = "Education & Publications"
    }
    return { ...item, label }
  })
}

const Facets = ({ slugSearch }) => (
  <aside  style={{ position: "sticky", top: "130px",padding:0 }}>
    <h4 style={{ fontWeight: "600", fontSize: "18px" }} className="mb-3">
      FILTERS
    </h4>
    <hr style={{ marginTop: "21px", marginBottom: "21px" }} />
    <CategoryWrapper>
      <Panel header="Categories" className="mt-1">
        <RefinementList
          attribute="categories_slugs.slug"
          defaultRefinement={[slugSearch]}
          operator="and"
          showMore={false}
          limit={100}
          escapeFacetValues={false}
          transformItems={transformItems}
        />
      </Panel>
    </CategoryWrapper>

    <CategoryWrapper>
      <Panel header="Initiatives">
        <RefinementList attribute="initiative" defaultRefinement="COA" operator="and" showMore={false} limit={7} />
      </Panel>
    </CategoryWrapper>
    <hr style={{ marginTop: "21px", marginBottom: "21px" }} />
    <ClearAllButton >
      <ClearRefinements
     
        translations={{
          reset: "Clear all",
        }}
      />
    </ClearAllButton>
  </aside>
)

const ClearAllButton = styled.div`
  button {
    background-color: var(--danger);
    color: #fff;
    border: 1px solid var(--danger);
    box-shadow: unset;
    display: block;
    padding: 14px 10px;
    border-radius: 0.245rem;
    font-size: 16px;
    cursor: pointer;
     width: 100%;

    &.ais-ClearRefinements-button--disabled {
      background-color: #b9b9b9;
      border-color: #b9b9b9;
    }
  }
`;