import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Form,
  Label,
} from "reactstrap";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import myCOALogo1 from "../../assets/images/mycoa-logo-v1.svg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

const PostTeaser = ({ closable = false}) => {
  const MySwal = withReactContent(Swal);
  const [showTeaser, setShowTeaser] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();
  const handleLogin = () => {
    const returnPath = window.location.pathname;
    loginWithRedirect({ appState: { returnTo: `${returnPath}` } });
  };
  const [userMetadata, setUserMetadata] = useState(null);
  const domain = "login.mycoa.io";
  const [modal, setModal] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [name, setName] = useState("");
  const [mailNotification, setMailNotification] = useState(false);
  const getUserMetadata = async () => {
    try {
      const accessToken1 = await getAccessTokenSilently({
        audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken1}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();
      setUserMetadata(user_metadata);
    } catch (e) {
      console.log(e.message);
    }
  };

  // // Code hidden below and added new condition without timer 
  // useEffect(() => {
  //   let timer1;
  //   let timer2;

  //   const getUserMetadata = async () => {
  //     try {
  //       const accessToken1 = await getAccessTokenSilently({
  //         audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
  //         scope: "read:current_user",
  //       });

  //       const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  //       const metadataResponse = await fetch(userDetailsByIdUrl, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken1}`,
  //         },
  //       });
  //       const dataval = await metadataResponse.json();
  //       setEmailValue(dataval.email);
  //       //const { user_metadata  } = await metadataResponse.json();
  //       const user_metadata = dataval.user_metadata;
  //       setName(user_metadata.first_name + " " + user_metadata.last_name);
  //       return user_metadata;
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };
  //   getUserMetadata().then(function (result) {
  //     const lockScroll = () => {
  //       if (!isAuthenticated) {
  //         timer1 = setTimeout(() => {
  //           document.querySelector("body").classList.add("overflow-hidden");
  //           setShowTeaser(true);
  //         }, 800);
  //       }
  //       //else if (isAuthenticated && result && result.COA !== "COA") {
  //       else if (
  //         isAuthenticated &&
  //         result &&
  //         result.initiative.split(",").indexOf("COA") == -1 &&
  //         result != undefined
  //       ) {
  //         timer2 = setTimeout(() => {
  //           document.querySelector("body").classList.add("overflow-hidden");
  //           setShowTeaser(true);
  //         }, 800);
  //       } else {
  //         // Hide the teaser after logged in by changing the setCurrentUser state
  //         document.querySelector("body").classList.remove("overflow-hidden");
  //       }
  //     };

  //     lockScroll();
  //     //getUserMetadata();
  //     console.log("mount");
  //     return () => {
  //       clearTimeout(timer1);
  //       clearTimeout(timer2);
  //       document.querySelector("body").classList.remove("overflow-hidden");
  //       console.log("unmount");
  //     };
  //   });
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestFormVal = "";
    if (
      e.target.requestForm.value == "" ||
      e.target.requestForm.value == null
    ) {
      requestFormVal = "Please grant permission for COA.";
    } else {
      requestFormVal = e.target.requestForm.value;
    }
    const formData = {
      email: e.target.email.value,
      name: e.target.name.value,
      requestForm: requestFormVal,
      initiative: "COA",
    };
    if (formData) {
      const baseURL = "https://mycoa.io/api";
      //console.info("formData", formData)
      axios
        .post(`${baseURL}/email-template/initative`, formData)
        .then((response) => {
          if (response.data.message == "Request Mail Sent Successfully") {
            setMailNotification(true);
          }
        });
    }
  };

  useEffect(() => {
    getUserMetadata();
    window.onpopstate = (e) => {
      document.querySelector("body").classList.remove("overflow-hidden");
    };
  }, [getAccessTokenSilently, user?.sub]);

  const toggle = () => setModal(!modal);

  console.log('Teaser userMetadata ', userMetadata)
  console.log('isAuthenticated ', isAuthenticated)

  var newCondition = (!isAuthenticated || (userMetadata && userMetadata?.initiative?.split(",")?.indexOf("COA") == -1 ))
  console.log('newCondition for show Teaser', newCondition)

  if(newCondition){
    document.querySelector("body").classList.add("overflow-hidden");
  }else{
    document.querySelector("body").classList.remove("overflow-hidden");
  }

  return (
    // <PostTeaserWrapper className={` ${showTeaser ? "show" : "false"}`}>
    <PostTeaserWrapper className={` ${(newCondition) ? "show" : "false"}`}>
      <div className="teaser-wrapper-inner">
        <div className="spacer"></div>

        <div className="teaser-container pb-7">
          {!isAuthenticated ? (
            <TeaserAlert className="bg-white px-3 py-4">
              <h5 className="text-center text-secondary mb-0">
                Attention: COA recently updated our login system. <br />
                In order to access an existing account, please reset your
                password! <br />
                Thank you and we apologize for the inconvenience.
              </h5>

              {closable && 
              <div
                onClick={() => {
                  closable(false);
                  document.querySelector("body").classList.remove("overflow-hidden");
                }}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                  color: "red",
                  fontSize: "3rem",
                }}
              > &times;
              </div>
              }
            </TeaserAlert>
          ) : null}

          <div className="teaser-container-inner pt-7 px-3">
            <div className="text-center mb-2">
              <img src={myCOALogo1} alt="logo" width="200px" />
            </div>
            {!isAuthenticated ? (
              <h3 className="teaser-title">LOGIN</h3>
            ) : (
              <h3 className="teaser-title request">Permission Denied</h3>
            )}

            {!isAuthenticated && (
              <p className="text-center login-desc">
                Sign into your myCOA Account with your email for complete
                access.
              </p>
            )}

            {!isAuthenticated ? (
              <div>
                <Button
                  color="secondary"
                  block
                  className="mb-1"
                  onClick={handleLogin}
                >
                  Sign In
                </Button>

                <p className=" mt-2 text-center">
                  New here? {"  "}
                  <a
                    href="https://mycoa.io/join"
                    className="text-underline"
                    style={{ color: "#57ADEB" }}
                  >
                    Create a myCOA Account
                  </a>
                </p>
                <hr />
                <p className="text-center mb-0 disclaimer">
                  COA collects and uses personal data in accordance with our
                  <a href="https://communityoncology.org/privacy-policy/">
                    {" "}
                    Privacy Policy
                  </a>
                  . By logging in, you agree to our{" "}
                  <a href="https://communityoncology.org/terms-and-conditions/">
                    {" "}
                    Terms and Conditions
                  </a>
                  .
                </p>
              </div>
            ) : (
              <div className="">
                <p className="text-center mb-3" style={{ fontSize: "18px" }}>
                  You do not have access to view this page. If you would like
                  access, please request below.
                </p>
                <Button
                  color="secondary"
                  block
                  className="mt-1 mb-4"
                  onClick={toggle}
                >
                  Request Access
                </Button>
                <hr />
                <p className="text-center mb-0 disclaimer">
                  COA collects and uses personal data in accordance with our
                  <a href="https://communityoncology.org/privacy-policy/">
                    {" "}
                    Privacy Policy
                  </a>
                  . By logging in, you agree to our{" "}
                  <a href="https://communityoncology.org/terms-and-conditions/">
                    {" "}
                    Terms and Conditions
                  </a>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}

      <div className={`custom-modal ${modal ? "showModal" : "hideModal"}`}>
        <Card>
          <CardHeader className="text-dark ">Request Form</CardHeader>
          <CardBody className="text-dark">
            <Form
              onSubmit={handleSubmit}
              className={`${!mailNotification ? "" : "hide"}`}
            >
              <FormGroup>
                {/* <Label for="exampleText">Request Detail</Label> */}
                <Input
                  type="hidden"
                  name="email"
                  id="email"
                  value={emailValue}
                />

                <Input type="hidden" name="name" id="name" value={name} />
                <Input
                  type="text"
                  name="requestForm"
                  id="requestForm"
                  placeholder="Please grant me the access to COA site."
                />
              </FormGroup>
              <Button
                color="secondary"
                size="sm"
                className="py-1 px-3"
                style={{ fontSize: "16px", fontWeight: "400" }}
                block
              >
                Submit
              </Button>
            </Form>
            <div className={`${mailNotification ? "" : "hide"}`}>
              <p>We have received your request and will contact you shortly!</p>
            </div>
          </CardBody>
          <CardFooter className="text-right">
            <Button
              outline
              color="danger"
              className="py-1 px-3"
              style={{ fontSize: "16px", fontWeight: "400" }}
              onClick={toggle}
            >
              Close
            </Button>
          </CardFooter>
        </Card>
      </div>
    </PostTeaserWrapper>
  );
};

export default PostTeaser;

export const PostTeaserWrapper = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  color: #fff;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 40%, transparent);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 300ms ease-in;
  opacity: 0;
  visibility: hidden;
  z-index: -1;

  &.show {
    display: flex;
    opacity: 1;
    visibility: visible;
    z-index: 9998;

    .teaser-container {
      transform: translateY(0%);
    }
  }

  .spacer {
    flex: 1 1 100%;
    z-index: 999;
    min-height: calc(100% - 70vh);
  }

  .btn {
    font-size: 25px;
    font-weight: 600;
  }

  .disclaimer {
    font-size: 12px;
    font-weight: 300;
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;

    a {
      text-decoration: underline;
    }
  }

  .login-desc {
    max-width: 270px;
    margin-right: auto;
    margin-left: auto;
    font-size: 15px;
    font-weight: 300;
  }

  a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .teaser-container {
    width: 100%;
    background-color: rgba(9, 91, 145, 0.9);

    transition: all 300ms ease;
    /* padding: 15px; */
    transform: translateY(100%);

    .teaser-title {
      color: #fff;
      text-align: center;
      font-weight: 600;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        max-width: 100px;
        width: 100%;
        height: 1px;
        background-color: #fff;
        top: 50%;
        transform: translateY(-50%);
        left: 22px;
      }

      &::after {
        content: "";
        position: absolute;
        max-width: 100px;
        width: 100%;
        height: 1px;
        background-color: #fff;
        top: 50%;
        transform: translateY(-50%);
        right: 22px;
      }

      @media (max-width: 420px) {
        &::before,
        &::after {
          max-width: 50px;
        }
        &::before {
          left: 32px;
        }

        &::after {
          right: 32px;
        }
      }

      &.request {
        &::before {
          max-width: 10px;
          left: 5px;
        }

        &::after {
          max-width: 10px;
          right: 5px;
        }

        @media (max-width: 425px) {
          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .teaser-container-inner {
      max-width: 400px;
      margin: 0px auto;
    }
  }

  .custom-modal {
    position: absolute;
    top: 50% !important;
    left: 50%;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    transform: translate(-50%, -50%);
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease;

    &.showModal {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }

    .card {
      width: 100%;
    }
  }
`;

export const TeaserAlert = styled.section`
  h5 {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    line-height: 1.3;
    font-size: 18px;
  }
`;
