import { Icon } from "@iconify/react/dist/iconify.js"
import React from "react"
import { Plus } from "react-feather"
import styled from "styled-components"
import { getDateTime } from "../../utils/helper"
// import { createEvents } from "ics"
// import { saveAs } from "file-saver"

const AddToCalendar = ({ post }) => {
  const eventData = post.event_data

  const eventLocation1 = `${eventData.venue}, ${eventData.address1}, ${
    eventData?.address2 ? `${eventData?.address2},` : ""
  }`

  // city state zip
  const eventLocation2 = `${eventData.city}, ${eventData.state}, ${eventData.zip_code}, ${eventData.country}`

  // "event_data": {
  //   "date": "2025-01-20T18:30:00.000Z",
  //   "start_time": "20:01",
  //   "end_time": "21:01",
  //   "event_info": {
  //     "busy": null,
  //     "calendarId": "a5515c73-bf81-4a63-9ad2-072d4cc6f56e",
  //     "description": "<p>Event Description</p>",
  //     "icalUid": "cbd79b91-0f78-49a1-8fd5-64aa5ba8a223",
  //     "location": "New York",
  //     "organizer": {
  //       "name": "",
  //       "email": "virtual-calendar"
  //     },
  //     "participants": [],
  //     "resources": [],
  //     "title": "Event Test",
  //     "grantId": "9351626b-19d4-4935-aed5-604c28767804",
  //     "id": "cbd79b91-0f78-49a1-8fd5-64aa5ba8a223",
  //     "object": "event",
  //     "status": "confirmed",
  //     "when": {
  //       "object": "timespan",
  //       "startTime": 1737469860,
  //       "endTime": 1737473460
  //     },
  //     "createdAt": 1737984743,
  //     "updatedAt": 1737984743
  //   }
  // },

  // const createICS = () => {
  //   const eventInfo = eventData.event_info;
  //
  //   // Extract the start and end times from the 'when' object (UNIX timestamps)
  //   const start = new Date(eventInfo.when.startTime * 1000); // Convert to milliseconds
  //   const end = new Date(eventInfo.when.endTime * 1000); // Convert to milliseconds
  //
  //   // Prepare the event data
  //   const event = {
  //     title: eventInfo.title,
  //     description: eventInfo.description.replace(/<\/?[^>]+(>|$)/g, ""), // Strip HTML tags
  //     location: eventInfo.location,
  //     start: [
  //       start.getFullYear(),
  //       start.getMonth() + 1, // Months are 0-indexed in JavaScript
  //       start.getDate(),
  //       start.getHours(),
  //       start.getMinutes(),
  //     ],
  //     end: [
  //       end.getFullYear(),
  //       end.getMonth() + 1, // Months are 0-indexed in JavaScript
  //       end.getDate(),
  //       end.getHours(),
  //       end.getMinutes(),
  //     ],
  //   };
  //
  //   // Create and download the ICS file
  //   createEvents([event], (error, value) => {
  //     if (!error) {
  //       const blob = new Blob([value], { type: "text/calendar" });
  //       saveAs(blob, "event.ics");
  //     } else {
  //       console.error("Error creating ICS:", error);
  //     }
  //   });
  // };

  const downloadICS = () => {
    const eventURL = eventData.event_url

    if (!eventURL) {
      console.error("No event URL provided")
      return
    }

    // Open the event URL in a new tab
    window.open(eventURL, "_blank")
  }

  const startTime = getDateTime(eventData.start_date)
  const endTime = getDateTime(eventData.end_date)
  const isSameDay = startTime.toDateString() === endTime.toDateString()

  return (
    <Wrapper>
      {/* <h6 className="text-secondary" style={{ fontWeight: "600", marginBottom: "10px" }}>
        Add to your Calendar
      </h6> */}
      <article>
        <h5 className="mb-2">{eventData?.event_info?.title || post?.title}</h5>
        <div className="meta mb-1">
          {/* {new Date(startTime).toLocaleString("en-GB", {
            weekday: "short",
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })}
          {" - "}
          {isSameDay
            ? new Date(endTime).toLocaleString("en-GB", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })
            : new Date(endTime).toLocaleString("en-GB", {
                weekday: "short",
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })} */}
          <Timeline class="py-5">
            <ul class="timeline-with-icons">
              {isSameDay ? (
                <li class="timeline-item mb-1">
                  <span class="timeline-icon">
                    <Icon icon="mdi:calendar-month-outline" />
                  </span>
                  <div className="">
                    {new Date(startTime).toLocaleString("en-US", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </div>
                  <div class="text-muted mb-2">
                    {new Date(startTime).toLocaleString("en-GB", {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </div>
                </li>
              ) : (
                <>
                  <li class="timeline-item mb-1">
                    <span class="timeline-icon">
                      <Icon icon="mdi:calendar-month-outline" />
                    </span>
                    <div className="">
                      {new Date(startTime).toLocaleString("en-US", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </div>
                    <div class="text-muted mb-2">
                      {" "}
                      {new Date(startTime).toLocaleString("en-GB", {
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </div>
                  </li>
                  <li class="timeline-item mb-1">
                    <span class="timeline-icon">
                      <Icon icon="mdi:calendar-month-outline" />
                    </span>
                    <div className="">
                      {new Date(endTime).toLocaleString("en-US", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </div>
                    <div class="text-muted mb-2">
                      {" "}
                      {new Date(endTime).toLocaleString("en-GB", {
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </div>
                  </li>
                </>
              )}
              <li class="timeline-item mb-1">
                <span class="timeline-icon">
                  <Icon icon="tdesign:map-location" fontSize={"18px"} style={{ minWidth: "18px" }} />
                </span>
                <div>{eventLocation1}</div>
                <div class="text-muted mb-2">{eventLocation2}</div>
              </li>
            </ul>
          </Timeline>
        </div>
        {/* <div className="desc mb-50">
          <Icon
            icon="tdesign:map-location"
            fontSize={"18px"}
            style={{ minWidth: "18px" }}
          />
          <div>
            <div>{eventLocation1}</div>
            <div>{eventLocation2}</div>
          </div>
        </div> */}

        <div className="d-flex flex-wrap mt-3" style={{ gap: "15px" }}>
          {/* <button>
            <img src="/images/google-calendar.svg" width={"30px"} alt="google calendar" /> Google
          </button>
          <button>
            <img src="/images/outlook.svg" width={"30px"} alt="outlook" /> Outlook
          </button>
          <button>
            <img src="/images/apple.svg" width={"30px"} alt="apple" /> Apple
          </button> */}
          <button onClick={downloadICS} className="d-flex align-items-center" style={{ gap: "5px" }}>
            <Icon icon="mdi:calendar-plus-outline" fontSize={"18px"} /> Add to Calendar
          </button>
        </div>
      </article>
    </Wrapper>
  )
}

export default AddToCalendar

export const Wrapper = styled.div`
  margin-top: 40px;
  background: #f6f6f6;
  padding: 25px;
  border-radius: 3px;
  cursor: pointer;

  h5 {
    font-weight: 600;
  }
  .meta {
    color: #808080;
    font-weight: 500;
    font-size: 18px;
  }

  .desc {
    color: #808080;
    display: flex;
    align-items: start;
    gap: 5px;

    svg {
      margin-top: 3px;
    }
  }

  button {
    box-shadow: unset;
    outline: unset;
    border-radius: 10px;
    border: 1px solid var(--primary);
    background-color: #fff;
    padding: 8px 20px;
    font-weight: 600;
    transition: background 300ms ease;
    color: var(--primary);

    &:hover {
      background-color: #d9e2ff;
      border-color: #a9b8e5;
    }
  }
`

export const Timeline = styled.div`
  ul {
    padding-left: 33px;
    left: 10px;
  }

  .weight-500 {
    font-weight: 500;
  }

  .timeline-with-icons {
    border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
  }

  .timeline-with-icons .timeline-item {
    position: relative;
  }

  .timeline-with-icons .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
  }

  .timeline-with-icons .timeline-icon {
    position: absolute;
    left: -48px;
    background-color: #e6e6e6;

    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timeline-item {
    font-size: 16px;
    font-weight: 400;
  }
`
