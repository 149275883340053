import React from "react"
import { Plus } from "react-feather"
import styled from "styled-components"
// import { createEvents } from "ics"
// import { saveAs } from "file-saver"

const AddToCalendar = ({ post }) => {
  const eventData = post.event_data

  const eventLocation = `${eventData.venue}, ${eventData.address1}, ${eventData?.address2 ? `${eventData?.address2},` : ","} ${eventData.city}, ${eventData.state}, ${eventData.zip_code}, ${eventData.country}`; 

  // "event_data": {
  //   "date": "2025-01-20T18:30:00.000Z",
  //   "start_time": "20:01",
  //   "end_time": "21:01",
  //   "event_info": {
  //     "busy": null,
  //     "calendarId": "a5515c73-bf81-4a63-9ad2-072d4cc6f56e",
  //     "description": "<p>Event Description</p>",
  //     "icalUid": "cbd79b91-0f78-49a1-8fd5-64aa5ba8a223",
  //     "location": "New York",
  //     "organizer": {
  //       "name": "",
  //       "email": "virtual-calendar"
  //     },
  //     "participants": [],
  //     "resources": [],
  //     "title": "Event Test",
  //     "grantId": "9351626b-19d4-4935-aed5-604c28767804",
  //     "id": "cbd79b91-0f78-49a1-8fd5-64aa5ba8a223",
  //     "object": "event",
  //     "status": "confirmed",
  //     "when": {
  //       "object": "timespan",
  //       "startTime": 1737469860,
  //       "endTime": 1737473460
  //     },
  //     "createdAt": 1737984743,
  //     "updatedAt": 1737984743
  //   }
  // },

  // const createICS = () => {
  //   const eventInfo = eventData.event_info;
  //
  //   // Extract the start and end times from the 'when' object (UNIX timestamps)
  //   const start = new Date(eventInfo.when.startTime * 1000); // Convert to milliseconds
  //   const end = new Date(eventInfo.when.endTime * 1000); // Convert to milliseconds
  //
  //   // Prepare the event data
  //   const event = {
  //     title: eventInfo.title,
  //     description: eventInfo.description.replace(/<\/?[^>]+(>|$)/g, ""), // Strip HTML tags
  //     location: eventInfo.location,
  //     start: [
  //       start.getFullYear(),
  //       start.getMonth() + 1, // Months are 0-indexed in JavaScript
  //       start.getDate(),
  //       start.getHours(),
  //       start.getMinutes(),
  //     ],
  //     end: [
  //       end.getFullYear(),
  //       end.getMonth() + 1, // Months are 0-indexed in JavaScript
  //       end.getDate(),
  //       end.getHours(),
  //       end.getMinutes(),
  //     ],
  //   };
  //
  //   // Create and download the ICS file
  //   createEvents([event], (error, value) => {
  //     if (!error) {
  //       const blob = new Blob([value], { type: "text/calendar" });
  //       saveAs(blob, "event.ics");
  //     } else {
  //       console.error("Error creating ICS:", error);
  //     }
  //   });
  // };

  const downloadICS = () => {
    const eventURL = eventData.event_url

    if (!eventURL) {
      console.error("No event URL provided")
      return
    }

    // Open the event URL in a new tab
    window.open(eventURL, "_blank")
  }


  return (
    <Wrapper >
      <h6 className="text-secondary" style={{ fontWeight: "600", marginBottom: "10px" }}>
        Add to your Calendar
      </h6>
      <article>
        <h5 className="mb-1">{eventData?.event_info?.title}</h5>
        {/* <div className="meta mb-1">April 29 - 30, 2025</div> */}
        <div className="meta mb-1"> {new Date(eventData?.start_date).toDateString()} </div>
        <p className="desc mb-50">{eventLocation}</p>

        <div className="d-flex flex-wrap mt-3" style={{ gap: "15px" }}>
          {/* <button>
            <img src="/images/google-calendar.svg" width={"30px"} alt="google calendar" /> Google
          </button>
          <button>
            <img src="/images/outlook.svg" width={"30px"} alt="outlook" /> Outlook
          </button>
          <button>
            <img src="/images/apple.svg" width={"30px"} alt="apple" /> Apple
          </button> */}
          <button onClick={downloadICS}>
            <Plus /> Add to Calendar
          </button>
        </div>
      </article>
    </Wrapper>
  )
}

export default AddToCalendar

export const Wrapper = styled.div`
  margin-top: 40px;
  background: #f6f6f6;
  padding: 25px;
  border-radius: 3px;
  cursor: pointer;

  h5 {
    font-weight: 600;
  }
  .meta {
    color: #707070;
    font-weight: 500;
  }

  .desc {
    color: #888;
  }

  button {
    box-shadow: unset;
    outline: unset;
    border-radius: 10px;
    border: 1px solid silver;
    background-color: #fff;
    padding: 8px 20px;
    font-weight: 600;
    transition: background 300ms ease;

    &:hover {
      background-color: #d9e2ff;
      border-color: #a9b8e5;
    }
  }
`
