import React from "react";
import { SinglePostWrapper } from "../_singlePostStyle";
import { Col, Row, Container, Button } from "reactstrap";
import SocialShare from "../../../../components/social-share/SocialShare";
import { Download, ExternalLink, FileText } from "react-feather";
import PostTeaser from "../../../../components/post-teaser/PostTeaser";

// images
import placeholderImage from "../../../../assets/images/comment-letters-placeholder.jpg";

import parse from "html-react-parser";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HorizontalArticle from "../../../../components/horizontal-article/HorizontalArticle";
import ArticleGridLayout from "../../../../components/article-grid-layout/ArticleGridLayout";
//import CareerResourcesPageView from './views/article/page-view/career-resources';
import CareerResourcesPageView from '../career-resources'
const CareerTemplate = (props) => {
  const {  isAuthenticated } = props;
  return <> <CareerResourcesPageView /> </>;
};

export default CareerTemplate;
