/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react"
import { SinglePostWrapper } from "../_singlePostStyle"
import { Col, Row, Container, Button, Card, CardImg, CardBody } from "reactstrap"
import SocialShare from "../../../../components/social-share/SocialShare"
import { ChevronRight, Download, ExternalLink, FileText, Star } from "react-feather"
import PostTeaser from "../../../../components/post-teaser/PostTeaser"
import { statesWithLink } from "../../../../dev-config/states"
// images
import placeholderImage from "../../../../assets/images/comment-letters-placeholder.jpg"

import parse from "html-react-parser"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import Select from "react-select"

import AddToCalendar from "../../../../components/article/AddtoCalendar"
import { getDateTime } from "../../../../utils/helper"
import { IconButton, Tooltip } from "@mui/material"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"

const EventTemplate = (props) => {
  const { post, relatedPosts, isAuthenticated, publicView } = props
  const { user } = useAuth0()
  const [fetchedStyle, setFetchedStyle] = useState(null)
  const [videoError, setVideoError] = useState(false)
  const [userMetadata, setUserMetadata] = useState(null)

  const favoriteEvents = userMetadata?.favorite_events || []

  const isFavorite = favoriteEvents.includes(post._id)

  const [isFav, setIsFav] = React.useState(isFavorite)

  const toggleFavoriteEvent = async (eventId) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/resource/make-favourite", {
        user_id: user.sub.replace("auth0|", ""),
        resource_id: eventId,
        type: favoriteEvents.includes(eventId) ? "Remove" : "Add",
      })

      const updatedUserMetadata = {
        ...user.user_metadata,
        favorite_events: favoriteEvents.includes(eventId)
          ? favoriteEvents.filter((id) => id !== eventId)
          : [...favoriteEvents, eventId],
      }

      setUserMetadata(updatedUserMetadata)

      return response.data
    } catch (error) {
      console.error("Error toggling favorite event:", error.response?.data || error.message)
      throw error
    }
  }

  useEffect(() => {
    if (user) {
      setUserMetadata(user.user_metadata)
    }
  }, [user])

  useEffect(() => {
    setIsFav(isFavorite)
  }, [isFavorite])

  useEffect(() => {
    if (post?.style) {
      setFetchedStyle((post?.style).replace(/\r\n|\r|\n/g, ""))
    }
  }, [post?.style])

  useEffect(() => {
    if (fetchedStyle) {
      const styleSheet = document.createElement("style")
      styleSheet.type = "text/css"
      styleSheet.innerText = fetchedStyle
      document.head.appendChild(styleSheet)

      return () => {
        document.head.removeChild(styleSheet)
      }
    }
  }, [fetchedStyle])

  const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#7367f01a", // for option hover bg-color
      primary: "#7367f0", // for selected option bg-color
      neutral10: "#04629C", // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed", // for input hover border-color
    },
  })

  let eventDate = ""

  if (post.type === "Event") {
    const eventData = post?.event_data || {}

    const startTime = getDateTime(eventData.start_date)
    const endTime = getDateTime(eventData.end_date)
    const isSameDay = startTime.toDateString() === endTime.toDateString()

    // April 25 - April 27, 2025
    eventDate = isSameDay
      ? startTime.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" })
      : startTime.toLocaleString("default", { month: "long", day: "numeric" }) +
        " - " +
        endTime.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" })
  }

  const handleVideoError = (e) => {
    setVideoError(true)
  }

  const handleToggleFavorite = () => {
    if (!isAuthenticated) {
      document.querySelector("body").classList.remove("overflow-hidden")
      return
    }

    setIsFav(!isFav) // Instant UI update
    toggleFavoriteEvent(post._id) // API call happens in the background
  }

  const activeButton = post.button_downloads ? post.button_downloads.find((button) => button.active === true) : null

  return post && post != undefined && post != "" ? (
    <>
      <PageTemplate id="page-wrapper" className="pb-3 pt-3 pt-lg-6 pb-lg-6">
        {/* <Helmet>
        <meta property="og:image" content="https://mycoa.communityoncology.org/static/media/REPORTS.e185e4ca86661f6f7ffe.jpg" />
        
      </Helmet> */}
        <Container>
          <Row className={` layout-wrapper ${post.name}`}>
            <Col lg="8" xl="9" className="mb-5 mb-lg-0">
              {post && post !== undefined ? (
                <article>
                  <h1 className="mb-0">
                    {post.title}
                    <Tooltip title="Bookmark" placement="top" arrow className="ml-1">
                      <IconButton
                        onClick={handleToggleFavorite}
                        // color={isFav ? "primary" : "default"}
                        className={isFav ? "bookmarked" : ""}
                        sx={{
                          "&.bookmarked": {
                            svg: {
                              fill: (theme) => theme.palette.primary.main,
                              color: (theme) => theme.palette.primary.main,
                            },
                          },
                        }}
                      >
                        <Star />
                      </IconButton>
                    </Tooltip>
                  </h1>
                  <hr />
                  <div class="meta d-flex align-items-center flex-wrap">
                    {eventDate ? (
                      <div className="date mr-3">{eventDate}</div>
                    ) : post?.date ? (
                      <div className="date mr-3">
                        {new Date(post.date).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="category mr-3">
                      {post.categories_slugs[0].name != undefined &&
                      post.categories_slugs[0].name != null &&
                      post.categories_slugs[0].name != ""
                        ? post.categories_slugs[0].name
                        : "CATEGORY"}{" "}
                      :{" "}
                      {post.categories_slugs[1].name != undefined &&
                      post.categories_slugs[1].name != "" &&
                      post.categories_slugs[1].name != null
                        ? post.categories_slugs[1].name
                        : "TAGS"}
                    </div> */}
                    {/* {post?.file && post?.file != "undefined" ? (
                      <div className="download">
                        <a href={post.file} download className="d-flex align-items-center">
                          Download <Download color="red" size="12" className="ml-1" />
                        </a>
                      </div>
                    ) : null} */}
                  </div>
                  <hr />
                  {post?.show_state_dropdown ? (
                    <>
                      <div className="content mt-2 mt-lg-2">
                        Links to State Boards of Pharmacy your practice can use to file complaints against
                        PBM-affiliated pharmacies on behalf of your practice and patients.
                      </div>
                      <Select
                        theme={selectThemeColors}
                        className="react-select mt-lg-3 ml-auto w-100 w-sm-auto mt-3 mt-sm-0"
                        classNamePrefix="select"
                        options={statesWithLink()}
                        isClearable={false}
                        id={"initiatives"}
                        // menuPlacement="top"
                        placeholder="Select the state"
                        onChange={(e) => {
                          window.open(e.value, "_blank")
                        }}
                        style={{ height: "38px", borderColor: "#ced4da" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {isAuthenticated || publicView == true ? (
                    <>
                      <div className="pt-3">
                        <div className="featured-image mb-4">
                          {post.mediaType === "image" && (
                            <img
                              src={
                                post?.feature_media &&
                                post?.feature_media != "null" &&
                                post?.feature_media != "undefined"
                                  ? post?.feature_media
                                  : placeholderImage
                              }
                              alt="featured image 3"
                              className="w-100 rounded"
                            />
                          )}

                          {post.mediaType === "video" &&
                            (post?.feature_media && !videoError ? (
                              <div className="embed-responsive embed-responsive-16by9 rounded">
                                <video
                                  className="embed-responsive-item"
                                  src={post?.feature_media}
                                  loop
                                  autoPlay
                                  controls
                                  muted
                                  onError={handleVideoError}
                                />
                              </div>
                            ) : (
                              <img
                                src={
                                  post?.feature_media &&
                                  post?.feature_media != "null" &&
                                  post?.feature_media != "undefined"
                                    ? post?.feature_media
                                    : placeholderImage
                                }
                                alt="featured image"
                                className="w-100 rounded"
                              />
                            ))}
                        </div>

                        {parse(`${post.short_description}`)}
                      </div>
                      {/* <div className="content mt-3 mt-lg-5">{parse(`${post.body}`)}</div> */}
                      {post?.type && post?.type === "Event" && post?.show_event && <AddToCalendar post={post} />}
                    </>
                  ) : (
                    <div id="noAuthCan">{/* {parse(`${post.short_description}`)} */}</div>
                  )}
                </article>
              ) : (
                <p>...</p>
              )}
            </Col>
            <Col lg="4" xl="3" className="mt-lg-0">
              <SidebarWrapper className="px-0">
                {/* {post.file && post?.file !== "undefined" ? (
                  <Button
                    color="secondary"
                    size=""
                    block
                    tag="a"
                    href={post.file}
                    download
                    outline
                    className="mb-40 d-flex align-items-center justify-content-center"
                  >
                    DOWNLOAD <Download size="13" className="ml-1 stroke-width-3px" />
                  </Button>
                ) : (
                  ""
                )} */}

                {activeButton?.title && <Button
                  color="secondary"
                  size=""
                  block
                  tag="a"
                  href={activeButton?.link || ""}
                  target="_blank"
                  outline
                  className="mb-40 d-flex align-items-center justify-content-center"
                >
                  {activeButton?.title || ""}
                </Button>}

                {/* {post?.show_map ? (
                  <Card className="mb-40">
                    <CardImg src="/images/pbm-tools-image.jpg" />

                    <CardBody>
                      <h5 className="text-center font-weight-bold mb-3">Policy Tracker & Maps</h5>
                      <small
                        className="text-center text-uppercase d-block mb-2"
                        style={{
                          color: "rgb(65, 128, 190)",
                          lineHeight: "1.3",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        Pending, passed & existing legislation across the states
                      </small>
                      <a
                        className="text-uppercase text-secondary d-flex align-items-center justify-content-center"
                        href="https://laws.mycoa.io/"
                        style={{ fontWeight: "500" }}
                        target="_blank"
                      >
                        <span>Learn More</span>
                        <ChevronRight size={"20"} />
                      </a>
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )} */}

                {post?.file_downloads?.length > 0 ? (
                  <div className="recommended-resources rounded mb-40">
                    <h3 className="text-center">File Download</h3>
                    <ul className="list-unstyled mb-0">
                      {post?.file_downloads?.map((file, index) => {
                        let postUrl = file?.link
                        if (!/^https?:\/\//.test(postUrl)) {
                          postUrl = "#"
                        }
                        if (file?.title && file?.link) {
                          return (
                            <li key={index + file?.link}>
                              <a
                                target={postUrl === "#" ? "_self" : "_blank"}
                                rel="noreferrer"
                                href={postUrl}
                                className="text-sm d-flex align-items-center"
                              >
                                <Download className="mr-2" size="16px" />
                                {file?.title}
                              </a>
                            </li>
                          )
                        }
                        return null
                      })}
                    </ul>
                  </div>
                ) : null}

                {post?.show_related_post ? (
                  <div className="recommended-resources rounded">
                    <h3 className="text-center">Upcoming</h3>
                    <ul className="list-unstyled mb-0">
                      {relatedPosts?.map((relatedPost, index) => {
                        let postUrl =
                          "/" +
                          relatedPost?.categories_slugs?.[0]?.slug +
                          "/" +
                          relatedPost?.categories_slugs?.[1]?.slug +
                          "/" +
                          relatedPost?.slug

                        let eventDate = ""

                        const eventData = relatedPost?.event_data || {}

                        const startTime = getDateTime(eventData.start_date)
                        const endTime = getDateTime(eventData.end_date)
                        const isSameDay = startTime.toDateString() === endTime.toDateString()

                        // April 25 - April 27, 2025
                        eventDate = isSameDay
                          ? startTime.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" })
                          : startTime.toLocaleString("default", { month: "long", day: "numeric" }) +
                            " - " +
                            endTime.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" })

                        return (
                          <li key={index + relatedPost?._id}>
                            <div className="event-date">
                              <span>{startTime.getDate()}</span>
                              <span>{startTime.toLocaleString("en-US", { month: "short" }).toUpperCase()}</span>
                            </div>
                            <div className="align-self-center">
                              <Link to={postUrl} className="d-flex align-items-center">
                                {relatedPost?.title}{" "}
                              </Link>
                              <div className="event-date-sm d-flex flex-xl-wrap mt-25" style={{ gap: "5px" }}>
                                <span>{eventDate}</span>
                                <Link to={postUrl} style={{ lineHeight: "1.2" }}>
                                  <ExternalLink size={"12px"} color="var(--link)" />
                                </Link>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ) : null}
              </SidebarWrapper>
            </Col>
          </Row>
        </Container>
      </PageTemplate>
    </>
  ) : (
    <p>...</p>
  )
}

export default EventTemplate

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 10%);

  hr {
    color: #d1d3d4;
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category,
  .download {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .content {
    img {
      max-width: 100%;
      height: auto;
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
      margin-top: 30px;
    }

    p + img {
      margin-bottom: 1rem;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  .featured-image img {
    aspect-ratio: 16/7;
    object-fit: cover;
  }

  .event-date {
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: block;
      text-align: center;

      &:first-child {
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
        margin-bottom: 2px;
      }

      &:last-child {
        color: #727272;
        font-weight: 600;
        font-size: 10px;
        line-height: 1;
      }
    }
  }

  /* Left sidebar */

  .layout-wrapper.leftSidebar {
    flex-direction: row-reverse;
  }
`

export const SidebarWrapper = styled.aside`
  padding-top: 0;
  .mb-40 {
    margin-bottom: 40px;
  }

  .recommended-resources {
    background-color: #f3f3f3;
    padding: 25px;

    h3 {
      font-size: 24px;
      font-weight: 700;
    }

    svg {
      stroke-width: 2.6px;
    }

    ul {
      li:not(:last-child) {
        margin-bottom: 20px;
      }

      li {
        display: flex;
        gap: 10px;

        .event-date-sm {
          font-size: 11px;
          color: #686868;
          font-weight: 500;

          &::first-letter {
            text-transform: capitalize;
          }
        }
      }
      a {
        color: var(--link);
        font-weight: 600;
        line-height: 1.3;
        &:not(:hover) {
          color: rgb(0, 0, 0, 0.87);
          font-weight: 600;
        }
      }
    }
  }

  h3 {
    margin-bottom: 15px;
  }
`
