export const menuItems = [
  {
    title: "Education & Publications",
    link: "#",
    target: "_self",
    rel: "nofollow",
    submenu: [
      {
        title: "COA Prescription",
        link: "/education-publications/report/coa-prescription",
        target: "_self",
      },
      {
        title: "Comment Letters",
        link: "/education-publications/comment-letters",
        target: "_self",
      },
      {
        title: "Studies",
        link: "/education-publications/studies",
        target: "_self",
      },

      {
        title: "Practice Impact Reports",
        link: "/education-publications/practice-impact-reports",
        target: "_self",
      },
      {
        title: "PBM Horror Stories",
        link: "/education-publications/pbm-horror-stories",
        target: "_self",
      },
      {
        title: "Position Statements",
        link: "/education-publications/position-statements",
        target: "_self",
      },
      {
        title: "All Publications",
        link: "/education-publications",
        target: "_self",
      },
    ],
  },
  {
    title: "Practice Resources",
    link: "#",
    target: "_self",
    rel: "nofollow",
    submenu: [
      {
        title: "COA Initiatives",
        link: "https://communityoncology.org/coa-initatives/",
        target: "_self",
      },
      {
        title: "COA Quality & Value Standards",
        link: "https://communityoncology.org/standards/",
        target: "_self",
      },
      {
        title: "Policy Tracker & Maps",
        link: "https://laws.mycoa.io",
        target: "_self",
      },
      {
        title: "Patient Feedback Tools",
        link: "https://communityoncology.org/patient-feedback-tools/",
        target: "_self",
      },
      {
        title: "Practice Benchmarking Software (COAnalyzer)",
        link: "https://coanalyzer.net",
        target: "_self",
      },
      {
        title: "Addressing PBM & Insurer Abuses",
        link: "https://mycoa.coapharmacy.com/resources/pbm-tools/resources-for-pbm-and-insurer-abuses",
        target: "_self",
      },
      {
        title: "COA TV",
        link: "https://communityoncology.org/coa-tv/",
        target: "_self",
      },
    ],
  },
  {
    title: "Advocacy",
    link: "#",
    target: "_self",
    rel: "nofollow",
    submenu: [
      {
        title: "Take Action Center",
        link: "https://communityoncology.org/advocacy/take-action/",
        target: "_self",
      },
      {
        title: "Patient Advocacy & Education (CPAN)",
        link: "https://coaadvocacy.org",
        target: "_self",
      },
      {
        title: "Sit in My Chair Elected Official Visits",
        link: "https://communityoncology.org/advocacy/sit-in-my-chair/",
        target: "_self",
      },
      {
        title: "State Legislative Leadership",
        link: "https://communityoncology.org/advocacy/state-legislative-leadership/",
        target: "_self",
      },
      {
        title: "Comment Letters",
        link: "/education-publications/comment-letters",
        target: "_self",
      },
      {
        title: "Position Statements",
        link: "/education-publications/position-statements",
        target: "_self",
      },
    ],
  },

  {
    title: "News & Updates",
    link: "#",
    target: "_self",
    rel: "nofollow",
    submenu: [
      {
        title: "Press Releases",
        link: "/news-updates/press-releases",
        target: "_self",
      },
      {
        title: "Latest Updates",
        link: "https://communityoncology.org/news-updates/latest-updates/",
        target: "_self",
      },
      {
        title: "Industry News",
        link: "https://communityoncology.org/news-updates/industry-news/",
        target: "_self",
      },
      {
        title: "Newsletters",
        link: "/news-updates/coa-newsletters",
        target: "_self",
      },
    ],
  },
  {
    title: "About",
    link: "#",
    target: "_self",
    rel: "nofollow",
    submenu: [
      {
        title: "Who We Are",
        link: "https://communityoncology.org/about/who-we-are/",
        target: "_self",
      },
      {
        title: "What is Community Oncology?",
        link: "/about/what-is-community-oncology/",
        target: "_self",
      },
      {
        title: "Our Members & Community",
        link: "https://communityoncology.org/about/our-member-and-community/",
        target: "_self",
      },

      {
        title: "Board & Leadership",
        link: "https://communityoncology.org/about/leadership-board/",
        target: "_self",
      },
      {
        title: "Committees & Boards",
        link: "https://communityoncology.org/about/committees/",
        target: "_self",
      },
      {
        title: "Contact Us",
        link: "https://communityoncology.org/contact-us",
        target: "_self",
      },
    ],
  },
];
