import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Form, Input, NavbarToggler } from "reactstrap";
import { useOnClickOutside } from "../../utils/useOnClickOutside";

import { Link } from "react-router-dom";
import menuItems from "./menuItemsTopbar";
import { ChevronDown, Menu, Search, X, Check } from "react-feather";
import Navbar from "./components/CustomNavbar";
import { useAuth0 } from "@auth0/auth0-react";
import history from "../../utils/history";
import { AvForm } from "availity-reactstrap-validation-safe";

const Topbar = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [formToggle, setFormToggle] = useState(false);
  const clearSearch = useRef();
  const searchFormRef = useRef();
  const saveSearch = useRef();
  const inputRef = useRef();
  const onMenuToggle = (e) => {
    setMenuToggle(!menuToggle);
  };
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const domain = "login.mycoa.io";

  const handleLogin = () => {
    const returnPath = window.location.pathname;
    loginWithRedirect({ appState: { returnTo: `${returnPath}` } });
  };

  const getSearchInput = (e) => {
    setSearchInput(e.target.value);

    // Enable the clear button
    if (!e.target.value) {
      clearSearch.current.style.display = "none";
    } else {
      clearSearch.current.style.display = "block";
    }
    // saveSearch.current.style.display = "block";
  };

  const clearSearchInput = (e) => {
    setSearchInput("");
    clearSearch.current.style.display = "none";
    saveSearch.current.style.display = "none";
    inputRef.current.focus();
  };

  const handleSearchToggle = () => {
    setFormToggle(true);
  };

  useOnClickOutside(searchFormRef, () => {
    setFormToggle(false);
    setSearchInput("");
    clearSearch.current.style.display = "none";
    saveSearch.current.style.display = "none";
  });

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        //else{window.location.pathname}
        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    if (
      window.location.href == "https://mycoa.communityoncology.org/" ||
      window.location.href == "https://mycoa.communityoncology.org" ||
      window.location.href == "http://localhost:3000" ||
      window.location.href == "http://localhost:3000/"
    ) {
      if (!isAuthenticated) {
        const returnPath = window.location.pathname;
        loginWithRedirect({ appState: { returnTo: `${returnPath}` } });
      }
    }
    getUserMetadata().then(function (result) {
      console.info("result", result);
    });
  }, [getAccessTokenSilently, user?.sub]);

  const searchFilter = () => {
    setFormToggle(false);
    setSearchInput("");
    clearSearch.current.style.display = "none";
    saveSearch.current.style.display = "none";
    window.location.href = "https://mycoa.io/search/?" + searchInput;
    // history.push('/search/'+searchInput)
  };

  return (
    <TopbarContainer id="topbar" className="d-flex justify-content-end ">
      <div className="top-menu">
        <NavbarToggler
          aria-controls="main-menu"
          className={`d-lg-none mr-0 ${menuToggle ? "active" : "disabled"}`}
          onClick={() => onMenuToggle()}
        >
          <div className="toggle-wrapper">
            <Menu color="#173372" size="18px" className="toggler-disabled" />
            <X color="#173372" size={"18px"} className="toggler-active" />
          </div>
        </NavbarToggler>
        <Navbar
          menuItems={menuItems}
          menuToggle={menuToggle}
          breakPoint="992"
        />
      </div>
      <SearchWrapper className="search-wrapper d-flex align-items-center ">
        <div
          className={`search ${formToggle ? "expanded" : "collapsed"}`}
          ref={searchFormRef}
        >
          <AvForm onSubmit={searchFilter}>
            <Search size="16px" className="search-icon" />
            <Input
              placeholder="Search..."
              value={searchInput}
              onChange={(e) => getSearchInput(e)}
              onClick={handleSearchToggle}
              ref={inputRef}
            />
          </AvForm>
          <X
            size={"16px"}
            className="clear-search mr-25"
            ref={clearSearch}
            onClick={(e) => clearSearchInput(e)}
          />
          <Check
            size={"16px"}
            type="submit"
            className="clear-search"
            ref={saveSearch}
            onClick={(e) => searchFilter(e)}
          />
        </div>
        <UserMenu className="user-menu">
          {isAuthenticated && (
            <li className="has-sub">
              <Link to="#">MyCOA</Link>
              <ChevronDown size={"20px"} className="dropdown-icon" />
              <div className="dropdown">
                <Link to="/profile">Profile</Link>
                <Link
  onClick={() =>
    logout({
      returnTo: "https://mycoa.communityoncology.org",
      federated: true, // ensures single logout across applications
    })
  }
>
  Logout
</Link>
              </div>
            </li>
          )}
          {!isAuthenticated && (
            <>
              <li className="has-sub">
                <a href="https://mycoa.io/join">JOIN</a>{" "}
                <span className="text-white">|</span>
                <Link
                  to="#"
                  onClick={handleLogin}
                  style={{ paddingLeft: "15px" }}
                >
                  LOGIN
                </Link>
              </li>
            </>
          )}
        </UserMenu>

        {/* <UserMenu className="user-menu">
          <li className="has-sub">
            <Link to="#">MyCOA </Link>
            <ChevronDown size={"20px"} className="dropdown-icon" />
            <div className="dropdown">
              <Link to="#">Profile</Link>
              <Link to="#">Logout</Link>
            </div>
          </li>
        </UserMenu> */}
      </SearchWrapper>
    </TopbarContainer>
  );
};

export default Topbar;

export const TopbarContainer = styled.div`
  border-bottom: 1px solid #d1d3d4;
  background-color: #fff;
  position: relative;
  z-index: 999;

  .navbar-toggler {
    position: relative;
    padding: 0px !important;
    background-color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: unset;
    align-self: center;
    height: 100%;
    outline: unset;

    .toggle-wrapper {
      position: absolute;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      right: 8px;
    }

    svg {
      position: absolute;
      stroke-width: 3px;
      /* transition: all calc(300 * 1ms) cubic-bezier(0.42, 0.01, 0.58, 1); */
      transition: all 300ms ease;
      opacity: 0;
      width: 17px;

      transform-origin: center center;
      opacity: 0;
    }

    &.disabled {
      .toggler-disabled {
        opacity: 1;
        transform: rotate(-360deg);
      }
    }

    &.active {
      .toggler-active {
        opacity: 1;
        transform: rotate(360deg);
      }
    }
  }

  @media (max-width: 992px) {
    min-height: 37px;

    .menus {
      li {
        .dropdown {
          li {
            a {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .menus {
    li {
      a {
        font-size: 16px;
        letter-spacing: 0.25px;
        padding: 5.46px 0px;
        line-height: 22px;
      }
      .dropdown {
        li {
          /* &:not(:last-child) {
            border-bottom: 1px solid rgba(65, 128, 190, 0.13);
          } */
          a {
            padding: 9px 20px;
            font-size: 14px;
            letter-spacing: 0.25px;
          }
        }
      }
    }
  }
`;

export const SearchWrapper = styled.div`
  background-color: var(--primary);
  padding-left: 5px;
  border-radius: 100px 0px 0px 100px;
  margin-left: 20px;

  .search {
    position: relative;
    width: 33px;

    &.expanded {
      width: 300px;

      input {
        background-color: #fff !important;
        width: 100%;
        padding-left: 50px;
        padding-right: 35px;
      }

      .search-icon {
        color: #000;
      }
    }

    .search-icon {
      position: absolute;
      display: flex;
      height: 100%;
      align-items: center;
      left: 15px;
      color: #fff;
      stroke-width: 4px;
      pointer-events: none;
    }

    .clear-search {
      position: absolute;
      right: 10px;
      display: flex;
      align-items: center;
      top: 0;
      height: 100%;
      stroke-width: 3px;
      color: var(--primary);
      cursor: pointer;
      display: none;
      z-index: 1;
    }

    input {
      height: 30px;
      border-radius: 100px;
      border: unset;
      padding-left: 30px;
      padding-right: 0;
      width: 0px;
      color: #000;
      font-weight: 500;
      background-color: transparent !important;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 15px;
    height: 100%;
    border-radius: 100px 0px 0px 100px;
    /* overflow: hidden; */
    li {
      height: 100%;
      & > a {
        display: inline-flex;
        /* background-color: #4180be; */
        width: 100%;
        height: 100%;
        align-items: center;
        font-weight: 400;
        z-index: 9;
        color: #fff;
        padding: 3px 15px;
        font-size: 16px;
        font-family: var(--secondaryFont) !important;
        padding-left: 25px;
        transition: color 200ms;
        line-height: 22px;
        letter-spacing: 0.5px;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  @media (max-width: 470px) {
    .expanded + ul {
      display: none;
    }

    .search {
      padding-right: 5px;

      .clear-search {
        right: 15px;
      }
    }
  }

  @media (max-width: 360px) {
    .search {
      max-width: 250px;
      input {
        width: 100%;
        transition: unset;
      }
    }
  }

  @media (max-width: 380px) {
    ul {
      a {
        font-size: 14px !important;
      }
    }
  }
`;
export const UserMenu = styled.ul`
  .has-sub {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #4180be;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    .dropdown-icon {
      color: #fff;
      position: relative;
      right: 10px;
      z-index: 99;
    }

    &:hover {
      .dropdown {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
    }

    .dropdown {
      position: absolute;
      top: 100%;
      right: auto;
      box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 8%);
      transition: all 300ms ease;
      font-family: var(--secondaryFont) !important;
      z-index: 9999;
      border-radius: 0px 0px 10px 10px;
      min-width: max-content;
      font-size: 14px;
      letter-spacing: 0.5px;
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      background-color: #fff;
      width: 100%;

      display: flex;
      flex-direction: column;

      a {
        padding: 6px 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        transition: all 300ms ease;
        font-weight: 400 !important;
        /* font-family: "Lato" !important; */
        color: var(--primary);

        &:not(:last-child) {
          border-bottom: 1px solid rgba(65, 128, 190, 0.07);
        }

        &:hover {
          background-color: #f7f7f7;
          color: var(--secondary);
        }
      }
    }
  }
`;
