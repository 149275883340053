import React, { useEffect, useState } from "react";
import { SinglePostWrapper } from "./_singlePostStyle";
import { Col, Row, Container } from "reactstrap";
import SocialShare from "../../../components/social-share/SocialShare";
import { FileText } from "react-feather";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useAuth0 } from "@auth0/auth0-react";

import axios from "axios";
import parse from "html-react-parser";
import { useParams, Link, useNavigate } from "react-router-dom";
import CommonTemplate from "./template/CommonTemplate";
import RecommendedVideos from "./template/RecommendedVideos";
import FullWidthTemplate from "./template/FullWidthTemplate";
import RecommendedResources from "./template/RecommendedResources";
import AccessDenied from "../../access-denied/AccessDenied.js";
import { Helmet } from "react-helmet";
import { trackVisit, updateTrackVisit } from "../../../utils/pageAnalytics.js";
import GridTemplate from "./template/GridTemplate.js";
import LandingPageTemplate from "./template/LandingPageTemplate.js";
import EventTemplate from "./template/EventTemplate.js";
import CareerTemplate from "./template/CareerTemplate.js";
import CanvasTemplate from "./template/CanvasTemplate.js";
import SinglePageTemplate from "./template/SinglePageTemplate.js";

const PageView = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const [userMetadata, setUserMetadata] = useState(null);
  const [accessDenied, setAccessDenied] = useState(false);

  const initiativeAccess = [process.env.REACT_APP_INITIATIVE];

  const domain = "login.mycoa.io";

  const { category, subcategory, slug } = useParams();
  const [previewData, setPreviewData] = useState(null);

  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [allPost, setAllPost] = useState([]);
  const [relatedResources, setRelatedResources] = useState([]);
  const [featuredResources, setFeaturedResources] = useState([]);
  const [categoryResources, setCategoryResources] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({});
  const [userResourceFavourite, setUserResourceFavourite] = useState({});
  //const history = useHistory();
  const navigate = useNavigate();
  const path = window.location.pathname.split('/');

  useEffect(() => {
    setPost(null);
    setRelatedPosts([]);
    axios
      .post(`${process.env.REACT_APP_API_URL}/resource/single-page`, {
        category_slug:path?.[1],
        sub_category_slug:path?.[2],
        slug: slug,
        limit: 8,
        initiative: initiativeAccess,
        user_id: user?.sub ? user?.sub.replace("auth0|", "") : null
      })
      .then((response) => {
        // if (response?.data?.data?.post && response?.data?.data?.post?.short_description == null) {
        //   response.data.data.post.short_description = response?.data?.data?.post?.body?.substring(0, 500) + ' ...'
        // } else if(response.data.data?.post) {
        //   response.data.data.post.short_description = response?.data?.data?.post?.short_description + ' ...'
        // }
        setPost(response.data.data.post);
        if (
          response.data.data.post === null ||
          response?.data?.data?.post?.allowed_categories_slugs.includes(category) == false ||
          response?.data?.data?.post?.allowed_categories_slugs.includes(subcategory) == false
        ) {
          setAccessDenied(true);
        } else if (
          response.data.data.post != null &&
          response.data.data.post.publish_type === "Save Draft"
        ) {
          setAccessDenied(true);
        }

        //Start - page analytics

        (async () => {
          const initiativeName = getNavData();
          const trackData = (await trackVisit(response.data.data.post, user?.sub, initiativeName))
            ?.data?.data;
          setAnalyticsData(trackData);
        })();
        //End - page analytics

        setRelatedPosts(response.data.data.related_posts);
        setAllPost(response.data.data.posts);
        setRelatedResources(response.data.data.related_resources);
        setFeaturedResources(response.data.data.featured_resources);
        setCategoryResources(response.data.data.category_resources);
        setUserResourceFavourite(response.data.data.favourite);
      });
  }, [slug]);
  const getNavData = () => {
    const referrer = document.referrer;
    console.log({ referrer });

    return "COA";
  };
  // useEffect(()=>{
  //   if(analyticsData){
  //     let startTime;
  //     let totalDuration = 0;
  //     const handleVisibilityChange = () => {
  //       if (document.visibilityState === 'hidden') {
  //         // When the tab is hidden, capture the current time
  //         startTime = Date.now();
  //       } else if (document.visibilityState === 'visible' && startTime) {
  //         // When the tab becomes visible again, calculate the duration
  //         const duration = Date.now() - startTime;
  //         totalDuration += duration;

  //         // Update backend with the session duration
  //         const data = JSON.stringify({
  //           track_id: analyticsData?._id,
  //           user_id: user?.sub,
  //           duration: totalDuration
  //         });

  //         navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/analytics/updatetrack`, data);
  //       }
  //     };

  //     const handleBeforeUnload = (event) => {
  //       event.preventDefault();
  //       const data = JSON.stringify({
  //         track_id: analyticsData?._id,
  //         user_id: user?.sub,
  //         duration: totalDuration
  //       });
  //       // const data = JSON.stringify({track_id:analyticsData?._id,user_id:user?.sub});
  //         navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/analytics/updatetrack`,data);
  //     };

  //     // window.addEventListener('visibilitychange', handleBeforeUnload);
  //     document.addEventListener('visibilitychange', handleVisibilityChange);
  //     window.addEventListener('focus', handleVisibilityChange);
  //     window.addEventListener('unload', handleBeforeUnload);
  //     window.addEventListener('beforeunload', handleBeforeUnload);
  //     return () => {
  //       // window.removeEventListener('visibilitychange', handleBeforeUnload);
  //       document.removeEventListener('visibilitychange', handleVisibilityChange);
  //       window.removeEventListener('focus', handleVisibilityChange);
  //       window.addEventListener('unload', handleBeforeUnload);
  //       window.addEventListener('beforeunload', handleBeforeUnload);
  //     };
  //   }
  // },[analyticsData])

  useEffect(() => {
    if (analyticsData) {
      let startTime = Date.now();
      let totalDuration = 0;
      let isTabActive = true;
      let lastTrackedDate = new Date().toDateString();

      // const updateServer = () => {
      //   if (analyticsData && totalDuration > 0) {
      //     const data = JSON.stringify({
      //       track_id: analyticsData?._id,
      //       user_id: user?.sub,
      //       duration: totalDuration,
      //       end_date: isTabActive ? null : new Date().toISOString(),
      //       isTabActive
      //     });

      //     navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/analytics/updatetrack`, data);
      //   }
      // };

      const updateServer = async () => {
        if (analyticsData && totalDuration > 0) {
          const currentDate = new Date().toDateString();
          const isNewDay = currentDate !== lastTrackedDate;
          if (totalDuration >= 480 * 60 * 1000 || isNewDay) {
            await submitAndResetTracking();
            lastTrackedDate = currentDate;
          } else {
            const data = JSON.stringify({
              track_id: analyticsData?._id,
              user_id: user?.sub,
              duration: totalDuration,
              end_date: isTabActive ? null : new Date().toISOString(),
              isTabActive,
            });
            navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/analytics/updatetrack`, data);
          }
        }
      };

      const submitAndResetTracking = async () => {
        if (analyticsData) {
          const newTrackingData = await trackVisit(post, user?.sub, getNavData());
          totalDuration = 0;
          setAnalyticsData(newTrackingData?.data?.data);
        }
      };

      const handleFocus = () => {
        isTabActive = true;
        startTime = Date.now();
        updateServer();
      };

      const handleBlur = () => {
        isTabActive = false;
        const activeTime = Date.now() - startTime;
        totalDuration += activeTime;
        updateServer();
      };

      const handleVisibilityChange = () => {
        if (document.visibilityState === "hidden") {
          handleBlur();
        } else {
          handleFocus();
        }
      };

      const handleClickEvent = (event) => {
        let target = event.target;
        // console.log('click',target);
         while (target && target.tagName !== "A") {
          target = target.parentElement;
        }

        if (target && target.tagName === "A" && target.href) {
          trackEvent(target?.dataset?.track, target.tagName);
          // trackEvent(target?.dataset?.track, target.tagName);
        }

        // if (target.dataset.track) {

        
      
        // }

        // if (target.tagName === "A" && target.dataset.track) {
        //   trackEvent(target?.dataset?.track,target.tagName)
        // }
      };

      const trackEvent = (elementName, elementType) => {
        // track_id,resource_id, elementName, elementType, timestamp
        const data = JSON.stringify({
          track_id: analyticsData?._id,
          elementName: elementName || "Click",
          elementType: elementType,
          timestamp: new Date().toISOString(),
        });

        navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/analytics/trackevent`, data);
      };
      // data-track="Subscribe Button"

      // Attach event listeners

      window.addEventListener("focus", handleFocus);
      window.addEventListener("blur", handleBlur);
      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Handle unload and beforeunload to save the final data
      const handleBeforeUnload = () => {
        handleBlur();
        updateServer();
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleBeforeUnload);
      document.addEventListener("click", handleClickEvent);

      return () => {
        // Cleanup event listeners
        window.removeEventListener("focus", handleFocus);
        window.removeEventListener("blur", handleBlur);
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleBeforeUnload);
        document.removeEventListener("click", handleClickEvent);
      };
    }
  }, [analyticsData]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata().then(function (result) {
      console.log("result", result);
      setUserMetadata(result);
      if (
        isAuthenticated &&
        result &&
        result.initiative.split(",").indexOf("COA") !== -1 &&
        result != undefined
      ) {
        document.querySelector("#authCan")?.classList.remove("hide");
        document.querySelector("#noAuthCan")?.classList.add("hide");
      } else {
        document.querySelector("#authCan")?.classList.add("hide");
        document.querySelector("#noAuthCan")?.classList.remove("hide");
      }
    });
  }, [getAccessTokenSilently, user?.sub]);

  const rendorTemplate = (templateName) => {
    var template = (
      <CommonTemplate
        post={post}
        relatedPosts={relatedPosts}
        isAuthenticated={isAuthenticated}
        publicView={
          post && (post.public_view == undefined || post.public_view == false) ? false : true
        }
      />
    );
    switch (templateName) {
      case "rightSidebar":
        template = template;
        break;
      case "leftSidebar":
        template = template;
        break;

      case "fullWidth":
        template = (
          <FullWidthTemplate
            post={post}
            relatedPosts={relatedPosts}
            allPost={allPost}
            isAuthenticated={isAuthenticated}
            publicView={
              post && (post.public_view == undefined || post.public_view == false) ? false : true
            }
          />
        );
        break;
      case "Recommended Videos":
        template = (
          <RecommendedVideos
            post={post}
            isAuthenticated={isAuthenticated}
            relatedPosts={relatedPosts}
            publicView={
              post && (post.public_view == undefined || post.public_view == false) ? false : true
            }
          />
        );
        break;
      case "Recommended Resources":
        template = (
          <RecommendedResources
            post={post}
            isAuthenticated={isAuthenticated}
            relatedPosts={relatedPosts}
            publicView={
              post && (post.public_view == undefined || post.public_view == false) ? false : true
            }
          />
        );
        break;
      case "Grid View":
        template = (
          <GridTemplate
            post={post}
            relatedPosts={relatedPosts}
            allPost={allPost}
            isAuthenticated={isAuthenticated}
            publicView={
              post && (post.public_view == undefined || post.public_view == false) ? false : true
            }
          />
        );
        break;

      case "Landing Page":
        template = (
          <LandingPageTemplate
            post={post}
            relatedPosts={relatedPosts}
            allPost={allPost}
            isAuthenticated={isAuthenticated}
            publicView={
              post && (post.public_view == undefined || post.public_view == false) ? false : true
            }
          />
        );
        break;
      case 'Career Template':
        template = <CareerTemplate
        isAuthenticated={isAuthenticated}
        />
        break;

      case 'Canvas Template':
        template = <CanvasTemplate
          post={post}
          relatedPosts={relatedPosts}
          allPost={allPost}
          isAuthenticated={isAuthenticated}
          publicView={
            post && (post.public_view == undefined || post.public_view == false) ? false : true
          }
        />
        break;

      case 'Single Page Template':
        template = <SinglePageTemplate
        post={post}
        relatedPosts={relatedPosts}
        allPost={allPost}
        isAuthenticated={isAuthenticated}
        publicView={
          post && (post.public_view == undefined || post.public_view == false) ? false : true
        }
        relatedResources={relatedResources}
        featuredResources={featuredResources}
        categoryResources={categoryResources}
        userResourceFavourite={userResourceFavourite}
        />
        break;
    }

    if (post?.type === "Event") { 
      template = (
        <EventTemplate
        post={post}
        relatedPosts={relatedPosts}
        allPost={allPost}
        isAuthenticated={isAuthenticated}
        publicView={
          post && (post.public_view == undefined || post.public_view == false) ? false : true
        }
        />
      );
    }
console.log(template,'singlePageTemplate')
    return template;
  };

  return !accessDenied ? (
    <>
      <Helmet>
        <title>{`${post?.title || ""}`}</title>
        <meta property="og:title" content={post?.title || ""} />
        <meta name="category" content={post?.categories_slugs?.[0]?.name || ""} />
        <meta name="subcategory" content={post?.categories_slugs?.[1]?.name || ""} />
        <meta property="article:published_time" content={post?.created_at || ""} />
        <meta property="og:description" content={post?.short_description || ""} />
        <meta property="og:site_name" content="https://mycoa.communityoncology.org/" />
      </Helmet>
      {rendorTemplate(post?.name)}
      {post && (post?.public_view === false || post?.public_view == undefined) ? (
        <PostTeaser />
      ) : (
        ""
      )}
    </>
  ) : (
    <AccessDenied />
  );
};

export default PageView;
