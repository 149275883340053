import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import HorizontalArticle from "../../components/horizontal-article/HorizontalArticle";

// images
import articlePlaceholder from "../../assets/images/comment-letters-placeholder.jpg";
import styled from "styled-components";
import Select from "react-select";

import { Bookmark, Search } from "react-feather";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { swalalertWithMsg, valSwalFailure, valSwalSuccess } from "../../dev-config/global";
import { useParams } from "react-router-dom";

const Post = () => {
  const { user } = useAuth0();
  const { sub_category_slug } = useParams();
  const [filter, setFilter] = useState({
    user_id: user?.sub.replace("auth0|", ""),
    page: 0,
    limit: 10,
    search: "",
    initiative:user?.initiative?.split(",").indexOf("COA") !== -1 ? ['COA'] : [user?.initiative?.split(",")[0]] || ['COA'],
    sub_category_slug,
    sort: {
      order: "asc",
      field: "display_order",
    },
    total_pages: 0,
    total_records: 0,
  });

  useEffect(() => {
    if (filter?.user_id) {
      handleUserFeatureResource();
    }
  }, [filter.page, filter.limit, filter.search, filter?.user_id, filter?.sort]);

  const [resourceList, setResourceList] = useState([]);


  const handleUserFeatureResource = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/resource/related-pagination-list`, filter)
      .then((response) => {
        console.log(response?.data, "response");
        setResourceList(response.data.data?.data);
        setFilter((prevState) => ({
          ...prevState,
          total_pages: response.data.data?.total_pages,
          total_records: response.data.data?.total_records,
        }));
      })
      .catch((err) => {
        console.log("error in initiative get");
      });
  };

  const handlePageChange = (newPage) => {
    if (newPage < 0 || newPage >= filter.total_pages) return; // Prevent out-of-range pages
    setFilter((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilter((prevState) => ({
        ...prevState,
        search: searchQuery,
        page: 0,
      }));
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleSortChange = (event) => {
    const newSortOrder = event.target.value;
    setFilter((prevState) => ({
      ...prevState,
      sort: {
        ...prevState.sort,
        order: newSortOrder,
      },
      page: 0,
    }));
  };

  const highlightStyle = {
    fontWeight: 'bold',
    backgroundColor: 'yellow'
  }

  const highlightText = (text, highlight) => {
    if (!highlight?.trim()) return text
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={highlightStyle}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  const highlightFuzzySearch = (texts, search) => {
    console.log(texts,'texts')
    return texts?.map((item, index) =>
      item?.type == 'hit' ? <span style={highlightStyle}>{(item.value)}</span> : (item?.value)
      )
  }

  return (
    <PageWrapper id="page-wrapper" className="py-6" isGradientBg={true}>
      <Container>
        <Card className="inner-wrapper border-0 pt-5">
          <h1 className="text-center text-capitalize mb-3">Related Articles</h1>

          <div style={{ maxWidth: "698px" }} className="mx-auto w-100">
            <SearchWrapper>
              <Input
                type="text"
                className="search"
                id="search"
                placeholder="Search for article"
                size={"lg"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="icon-wrapper">
                <Search size={"18px"} />
              </span>
            </SearchWrapper>

            <div className="d-flex-column d-sm-flex justify-content-between align-items-start">
              <span className="text-warning mb-2 d-block d-sm-inline mb-sm-0">{filter?.total_records} results found</span>
              <div
                className="d-flex flex-column flex-sm-row justify-content-between "
                style={{ gap: "15px" }}
              >
                <FormGroup className="mb-0">
                  <select onChange={handleSortChange} value={filter.sort.order}>
                    <option value={"asc"}>Ascending</option>
                    <option value={"desc"}>Descending</option>
                  </select>
                </FormGroup>
              </div>
            </div>
          </div>

          <hr className="mb-5 mt-5 border-primary" />

          <div className="article-container">
            {resourceList?.map((item, index) => {
                let category = item?.categories_slugs?.[0]?.slug;
                let subCategory = item?.categories_slugs?.[1]?.slug;
                let slug = item?.slug;
                let baseUrl = process.env.REACT_APP_BASE_COA;
                let redirectUrl = baseUrl + "/" + category + "/" + subCategory + "/" + slug;

              return (
                <article className="hit">
                  <div className="hit-image cursor-pointer">
                    <img
                      src={item?.feature_image ? item?.feature_image : articlePlaceholder}
                      alt="Article Placeholder"
                      style={{
                        borderRadius: "2px",
                        objectFit: "cover",
                        width: "100%",
                        aspectRatio: "20/13",
                      }}
                      onClick={()=>{window.open(redirectUrl)}}
                    />
                  </div>
                  <div className="product-desc-wrapper">
                    <div className="hit-content">
                      <h4 className="product-title cursor-pointer" onClick={()=>{window.open(redirectUrl)}}>
                        {item?.highlights?.texts
                        ? highlightFuzzySearch(item?.highlights?.texts, searchQuery)
                        : highlightText(item?.title, searchQuery)}
                        </h4>
                      <div className="hit-description">
                        <Box className="desc">{parse(item?.short_description)}</Box>

                        <div
                          class=" meta d-flex align-items-center flex-wrap "
                          style={{ gap: "0.5rem" }}
                        >
                          <Badge pill className="mr-50 mt-50 mr-1" color="background">
                            {item?.initiative?.join(",")}
                          </Badge>
                          <div className="divider" />
                          <span
                            className="cursor-pointer weight-500"
                            style={{ color: "var(--link)", fontWeight: 500 }}
                            onClick={()=>{window.open(`${baseUrl}/${category}`)}}
                          >
                            <b> {item?.categories_slugs?.[0]?.name}</b>{" "}
                          </span>{" "}
                          <div className="divider" />
                          <span
                            className="cursor-pointer weight-500"
                            style={{ color: "var(--link)", fontWeight: 500 }}
                            onClick={()=>{window.open(`${baseUrl}/${category}/${subCategory}`)}}
                          >
                            {item?.categories_slugs?.[1]?.name}
                          </span>{" "}
                          <div className="divider" />
                          <span className="date">
                            {" "}
                            {item?.date ? moment(item?.date).format("MMM DD, YYYY") : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
          <div className="ais-Pagination mt-5">
            <ul className="ais-Pagination-list">
              <li>
                <span
                  className="ais-Pagination-link"
                  onClick={() => handlePageChange(0)}
                  disabled={filter.page === 0}
                >
                  «
                </span>
              </li>
              <li>
                <span
                  className="ais-Pagination-link"
                  onClick={() => handlePageChange(filter.page - 1)}
                  disabled={filter.page === 0}
                >
                  ‹
                </span>
              </li>

              {/* Page numbers */}
              {Array.from({ length: filter.total_pages }, (_, index) => (
                <li key={index}>
                  <a
                    className={`ais-Pagination-link ${
                      filter.page === index ? "ais-Pagination-link--selected" : ""
                    }`}
                    onClick={() => handlePageChange(index)}
                    aria-label={`Page ${index + 1}`}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}

              <li>
                <span
                  className="ais-Pagination-link"
                  onClick={() => handlePageChange(filter.page + 1)}
                  disabled={filter.page === filter.total_pages - 1}
                >
                  ›
                </span>
              </li>
              <li>
                <span
                  className="ais-Pagination-link"
                  onClick={() => handlePageChange(filter.total_pages - 1)}
                  disabled={filter.page === filter.total_pages - 1}
                >
                  »
                </span>
              </li>
            </ul>
          </div>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default Post;

export const PageWrapper = styled.div`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 40%);
  position: relative;
  top: -2px;

  .inner-wrapper {
    padding: 30px;
    padding-bottom: 0;
    border-radius: 30px;
  }

  .hidden {
    display: none;
  }

  article {
    display: flex;
    gap: 24px;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgb(231, 231, 231);
      padding-bottom: 43px;
      margin-bottom: 43px;
    }

    &:hover {
      .hit-image {
        img {
          transform: scale(1.05);
        }
      }
    }

    .hit-image {
      flex: 0 0 250px;

      img {
        width: 100%;
        transition: all 0.3s ease 0s;
        aspect-ratio: 16 / 10;
        object-fit: cover;
        border-radius: 10px !important;
        cursor: pointer;
      }
    }

    .product-desc-wrapper {
      .product-title {
        margin-bottom: 5px;
        cursor: pointer;

        .ais-Highlight {
          * {
            color: var(--primary);
            display: inline;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            font-style: normal;
          }

          .ais-Highlight-highlighted {
            background-color: yellow;
          }
        }
      }

      .hit-description {
        .desc {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
        .ais-Snippet {
          * {
            display: inline !important;
            font-style: normal;
            margin-bottom: 0.8rem;
          }

          .ais-Snippet-highlighted {
            background-color: yellow;
          }
        }

        .meta {
          margin-top: 0.8rem;
          font-size: 14px;
          .cursor-pointer {
            cursor: pointer;
          }

          .badge {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            color: rgb(133, 146, 163);
          }

          .ais-Highlight {
            color: var(--gray);
            text-transform: uppercase;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            color: #8592a3;
          }

          .date {
            color: rgba(68, 68, 68, 0.6);
            text-transform: capitalize;
            font-weight: 500;
          }

          .divider {
            background: #bdbdbd;
            width: 8px;
            height: 8px;
            border-radius: 50px;
          }
        }
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;

      .hit-image {
        flex-basis: 100%;
      }
    }
  }
  .ais-Pagination {
    ul {
      margin-bottom: 0;
    }
    li:last-child {
      margin-bottom: 0.6rem;
    }
    a.ais-Pagination-link--selected {
      background-color: var(--primary);
      color: #fff;
    }
  }

  select {
    padding: 6px;
    border-color: silver;
    border-radius: 2px;
    min-width: 150px;
    width: 100%;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;

  input {
    padding-right: 48px;
    border-color: var(--primary);
    border-width: 2px;
    min-height: 56px;
  }

  .icon-wrapper {
    background-color: var(--primary);
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    padding: 10px 15px;
    color: #fff;
    overflow: hidden;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //
`;
