import React, { useEffect, useState } from "react";
import { SinglePostWrapper } from "../_singlePostStyle";
import { Col, Row, Container, Button } from "reactstrap";
import SocialShare from "../../../../components/social-share/SocialShare";
import {
  ArrowUpRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp,
  Download,
  ExternalLink,
  FileText,
  Search,
  X,
} from "react-feather";
import PostTeaser from "../../../../components/post-teaser/PostTeaser";

// images
import placeholderImage from "../../../../assets/images/comment-letters-placeholder.jpg";

import parse from "html-react-parser";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CanvasTemplate = (props) => {
  const { post, relatedPosts, isAuthenticated, publicView } = props;
  const [fetchedStyle, setFetchedStyle] = useState(null);

  const [searchOpen, setSearchOpen] = useState(false);
  const [chapterOpen, setChapterOpen] = useState(true);
  const [pageSearch, setPageSearch] = useState("");
  const [toggleSearch, setToggleSearch] = useState(false);
  const [currentPageSearch, setCurrentPageSearch] = useState("");
  const [totalOccurrences, setTotalOccurrences] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [highlights, setHighlights] = useState([]);
  let caseSensitive = false;

  const searchToggle = () => {
    setSearchOpen(!searchOpen);
  };

  const chapterToggle = () => {
    setChapterOpen(!chapterOpen);
  };
  // console.log("Test data", post?.style);
  useEffect(() => {
    if (post?.style) {
      setFetchedStyle((post?.style).replace(/\r\n|\r|\n/g, ""));
    }
  }, [post?.style]);

  useEffect(() => {
    if (fetchedStyle) {
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = fetchedStyle;
      document.head.appendChild(styleSheet);

      return () => {
        document.head.removeChild(styleSheet);
      };
    }
  }, [fetchedStyle]);

  const goTop = (keyWords, findFirst) => {
    // if(window.document.location.href = '#') {
    //     if(findFirst) {
    //         window.find(keyWords, 0, 0, 1);
    //     }
    // }
  };

  const clearHighlights = () => {
    highlights.forEach((highlight) => {
      const parent = highlight.parentNode;
      console.log(parent);
      if (parent) {
        parent.replaceChild(document.createTextNode(highlight.textContent), highlight);
        parent.normalize(); // Merge adjacent text nodes
      }
    });
    setHighlights([]);
    setTotalOccurrences(0);
    setCurrentIndex(0);
  };

  const scrollToHighlight = (element) => {
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const clearSearch = () => {
    const content = document.getElementById("page-wrapper");
    const children = content.querySelectorAll(".findings");
    children.forEach((child) => {
      child.classList.remove("highlight", "current", "findings"); // Remove both 'active' and 'highlighted' classes
    });

    setHighlights([]);
    setTotalOccurrences(0);
    setCurrentIndex(0);
    setPageSearch("");
    setToggleSearch(false);
  };

  const findPrevious = () => {
    let full = highlights.length - 1;

    if (highlights.length === 0) return;

    console.log({ currentIndex });

    // highlights[(currentIndex)%full]?.classList.remove('current','findings');
    if (document.getElementsByClassName("current")[0]) {
      document.getElementsByClassName("current")[0].className = "highlight";
    }
    // document.getElementsByClassName('current')[0].className = 'highlight'
    setCurrentIndex((old) => (old == 0 ? 0 : old - 1));
    if (currentIndex < 1) {
      setCurrentIndex(totalOccurrences); // Wrap around to the last occurrence
    }

    highlights[(currentIndex - 1) % full]?.classList.add("current", "findings");
    scrollToHighlight(highlights[currentIndex % full]);
  };
  const findNext = () => {
    let full = highlights.length - 1;
    console.log({ currentIndex }, "sa", highlights[currentIndex % full]);

    if (highlights.length === 0) return;

    // highlights[(currentIndex)%full]?.classList.remove('current','findings');
    console.log(document.getElementsByClassName("current"));
    if (document.getElementsByClassName("current")[0]) {
      document.getElementsByClassName("current")[0].className = "highlight";
    }

    // highlights[(currentIndex)%full]?.className='';
    // highlights[(currentIndex)%full]?.classList.remove('current','findings');

    console.log(highlights[currentIndex % full]?.classList);
    setCurrentIndex((old) => old + 1);

    if (currentIndex > totalOccurrences) {
      setCurrentIndex(1);
    }

    highlights[(currentIndex + 1) % full].classList.add("current", "findings");
    scrollToHighlight(highlights[currentIndex % full]);
  };

  const getTextNodes = (node) => {
    const textNodes = [];
    function traverse(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        textNodes.push(node);
      } else {
        for (let child of node.childNodes) {
          traverse(child);
        }
      }
    }
    traverse(node);
    return textNodes;
  };

  const test = () => {
    clearHighlights();
    setToggleSearch(true);
    if (!pageSearch) return;

    const content = document.getElementById("page-wrapper");
    const regex = new RegExp(pageSearch, "gi");
    const textNodes = getTextNodes(content);
    // setTotalOccurrences(0);
    setCurrentIndex(0);
    let count = 0;
    for (let i = 0; i < textNodes.length; i++) {
      let node = textNodes[i];
      const matches = node.textContent.match(regex);
      if (matches) {
        const parent = node.parentNode;
        const fragments = node.textContent.split(regex);
        let lastIndex = 0;

        for (let index = 0; index < fragments.length; index++) {
          let fragment = fragments[index];
          if (index > 0) {
            const highlight = document.createElement("span");
            // highlight.className = 'highlight';
            highlight.classList.add("highlight", "findings");
            // highlight.className = 'findings';
            highlight.textContent = matches[lastIndex++];
            parent.insertBefore(highlight, node);
            let a = [...highlights];
            a.push(highlight);
            setHighlights((old) => [...old, highlight]);
            // highlights.push(highlight);
          }
          const textNode = document.createTextNode(fragment);
          parent.insertBefore(textNode, node);
        }
        // fragments.forEach((fragment, index) => {
        //     if (index > 0) {
        //         const highlight = document.createElement('span');
        //         highlight.className = 'highlight';
        //         highlight.textContent = matches[lastIndex++];
        //         parent.insertBefore(highlight, node);
        //         let a = [...highlights];
        //         a.push(highlight)
        //         setHighlights(old=>([highlight,...old]));
        //         // highlights.push(highlight);
        //     }
        //     const textNode = document.createTextNode(fragment);
        //     parent.insertBefore(textNode, node);
        // });
        parent.removeChild(node);
        // console.log({fragments})
        count += fragments.length - 1;
        setTotalOccurrences((old) => old + (fragments.length - 1));
      }
    }
    // textNodes.forEach(node => {

    //     const matches = node.textContent.match(regex);

    //     if (matches) {
    //         const parent = node.parentNode;
    //         const fragments = node.textContent.split(regex);
    //         let lastIndex = 0;

    //         for (let index = 0; index < fragments.length; index++) {
    //           let fragment = fragments[index];
    //             if (index > 0) {
    //               const highlight = document.createElement('span');
    //               highlight.className = 'highlight';
    //               highlight.textContent = matches[lastIndex++];
    //               parent.insertBefore(highlight, node);
    //               let a = [...highlights];
    //               a.push(highlight)
    //               setHighlights(old=>([highlight,...old]));
    //               // highlights.push(highlight);
    //           }
    //           const textNode = document.createTextNode(fragment);
    //           parent.insertBefore(textNode, node);
    //         }
    //         // fragments.forEach((fragment, index) => {
    //         //     if (index > 0) {
    //         //         const highlight = document.createElement('span');
    //         //         highlight.className = 'highlight';
    //         //         highlight.textContent = matches[lastIndex++];
    //         //         parent.insertBefore(highlight, node);
    //         //         let a = [...highlights];
    //         //         a.push(highlight)
    //         //         setHighlights(old=>([highlight,...old]));
    //         //         // highlights.push(highlight);
    //         //     }
    //         //     const textNode = document.createTextNode(fragment);
    //         //     parent.insertBefore(textNode, node);
    //         // });
    //         parent.removeChild(node);
    //         // console.log({fragments})
    //         count += fragments.length-1
    //         setTotalOccurrences(old=>old+(fragments.length-1));

    //     }
    // });

    console.log({ count }, highlights);
    //

    // Scroll to the first occurrence
    if (count > 0) {
      setCurrentIndex(1);
    }
  };

  useEffect(() => {
    highlights[currentIndex]?.classList.add("current");
    // document.getElementById('currentIndex').innerText = currentIndex;
    scrollToHighlight(highlights[currentIndex]);
  }, [currentIndex]);


  return post && post != undefined && post != "" ? (
    <>
      <PageTemplate className="pb-3 pb-lg-6">

        <section id="canvas-template" className={`${post.name}`}>

          {post && post !== undefined ? (
            <article>
              {isAuthenticated || publicView == true ? (
                <div className="content">{parse(`${post.body}`)}</div>
              ) : (
                <div id="noAuthCan">{/* {parse(`${post.short_description}`)} */}</div>
              )}
            </article>
          ) : (
            <p>...</p>
          )}

        </section>

        {/* Chapter drawer design */}
        {post && post?.chapters ? (
          <ChapterWrapper className={`chapter-container ${chapterOpen ? "slideRight" : ""}`}>
            <div className="chapter-controls" onClick={chapterToggle}>
              <div className="chapter-title">
                {!chapterOpen ? <ChevronsRight color="#fff" /> : <ChevronsLeft color="#fff" />}
              </div>

            </div>
            <div>
              <div className={`search-container position-static`}>
                <h6 className="text-white text-center">Search for a term</h6>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div class="input-group input-group-lg">
                    <input
                      type="text"
                      class="form-control "
                      placeholder="Search"
                      aria-describedby="basic-addon1"
                      value={pageSearch}
                      onChange={(e) => setPageSearch(e.target.value)}
                    />
                    {toggleSearch && (
                      <div
                        class="bg-background d-flex align-items-center justify-content-center p-1"
                        style={{ fontSize: '12px' }}
                      >
                        {currentIndex}/{totalOccurrences}
                      </div>
                    )}
                    <div class="input-group-append ">
                      <span
                        class="input-group-text bg-white cursor-pointer " style={{ padding: '2px' }}
                        onClick={() => findPrevious()}
                      >
                        <ChevronUp name="pageSearch" className="text-primary" size={22} />
                      </span>
                      <span
                        class="input-group-text bg-white cursor-pointer " style={{ padding: '2px' }}
                        onClick={() => findNext()}
                      >
                        <ChevronDown name="pageSearch" className="text-primary" size={22} />
                      </span>
                      {!toggleSearch ? (
                        <span
                          class="input-group-text bg-white cursor-pointer " style={{ padding: '2px' }}
                          onClick={() => test()}
                        >
                          <Search name="pageSearch" className="text-primary" size={18} />
                        </span>
                      ) : (
                        <span
                          class="input-group-text bg-white cursor-pointer " style={{ padding: '2px' }}
                          onClick={() => clearSearch()}
                        >
                          <X name="pageSearch" className="text-primary" size={18} />
                        </span>
                      )}
                    </div>
                  </div>
                </form>

              </div>
              <div
                className="chapter-content"
                dangerouslySetInnerHTML={{
                  __html: post?.chapters,
                }}
              >

              </div>
            </div>

          </ChapterWrapper>
        ) : (
          ""
        )}
        {/* Chapter drawer design end*/}
      </PageTemplate>
    </>
  ) : (
    <p>...</p>
  );
};

export default CanvasTemplate;

export const PageTemplate = styled.section`
  color: var(--primary);
  font-weight: 500;
  hr {
    color: #d1d3d4;
  }

  .highlight {
    background-color: #ff6;
  }
  .current {
    background-color: orange;
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category,
  .download {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .content {
    a {
      word-break: break-all;
    }
    img {
      max-width: 100%;
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
      margin-top: 30px;
    }

    p + img {
      margin-bottom: 1rem;
    }

    & > :last-child {
      margin-bottom: 0;
    }

    .content-sm {
      max-width: 768px;
      margin: 0px auto;
    }
  }

  .search-container {
    position: fixed;
    background: rgba(35, 51, 112, 0.88);
    padding: 15px;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transition: all 300ms ease;
    backdrop-filter: blur(5px);

    &.slideDown {
      transform: translateY(114.79px);
    }

    form {
      max-width: 400px;
      margin: 0px auto;
    }

    &:focus-within {
      .form-control {
        border-right-color: silver;
      }

      .input-group-text {
        border-color: var(--primary);
      }
    }

    .input-group-lg > .form-control {
      border-radius: 0.6rem 0px 0px 0.6rem;

      &:focus {
        box-shadow: unset;
      }
    }

    .input-group-text {
      transition: 300ms ease;
      border-radius: 0rem 0.6rem 0.6rem 0rem;

      &:hover {
        background-color: #f1f3ff !important;
      }
    }

    .search-controls {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-35px);
      background: red;
      padding: 5px;
      cursor: pointer;
    }

    @media (min-width: 769px) {
      .input-group-lg > .form-control {
        border-right: 1px solid silver;

        &:focus {
          box-shadow: unset;
        }
      }
    }

    /* @media (max-width: 768px) {
      bottom: -20px;
      padding: 0px 15px;
    } */
  }
`;

export const ChapterWrapper = styled.section`
  position: fixed;
  top: 129px;
  z-index: 9;
  max-width: 300px;
  width: 100%;
  width: 100%;
  background-color: #fff;
  height: auto;
  transition: all 300ms ease;
  transform: translateX(-100%);

  @media (max-width: 992px) {
    top: 97px;
  }

  &.slideRight {
    transform: translateX(0);
  }

  .chapter-controls {
    position: relative;
    .chapter-title {
      background: var(--secondary);
      color: #fff;
      cursor: pointer;
      position: absolute;
      right: 0;
      left: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;

      svg {
        height: 35px;
      }
      /* span {
        border-top: 1px solid #fff;
        padding: 10px;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        text-transform: uppercase;
      } */
    }
  }

  .list-group-item .h5 {
    font-size: 18px;
  }
`;
