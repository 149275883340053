import { createTheme } from '@mui/material/styles';

var mainColor = '#173372';
var secondaryColor = '#e61b37';
var borderColor =  '#ced4da';
var borderFocusedColor = '#3365d6';

const MuiBootstrapTheme = createTheme({
  palette: {
    primary: {
      main: mainColor, // Bootstrap primary blue
    },
    secondary: {
      main: secondaryColor, // Bootstrap secondary gray
    },
    success: {
      main: '#28a745',
    },
    warning: {
      main: '#ffc107',
    },
    error: {
      main: '#dc3545',
    },
    info: {
      main: '#17a2b8',
    },
  },
  typography: {
    fontFamily: ['"Montserrat"', 'system-ui', 'sans-serif'].join(','),
    button: {
      textTransform: 'none', // Match Reactstrap buttons
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem', // Bootstrap button radius
          padding: '0.375rem 0.75rem', // Bootstrap button padding
          boxShadow: 'none', // Remove MUI's default shadow
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem', // Match Bootstrap card border-radius
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: borderColor, // Bootstrap input border
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
            },
            '&:hover fieldset': {
              borderColor: borderColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: borderFocusedColor,
              borderWidth: '1px',
              boxShadow:'0 0 0 0.2rem rgba(23, 51, 114, 0.25)'
            },
          },
        },
      },
    },
  },
});

export default MuiBootstrapTheme;
