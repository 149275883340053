import React from "react";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";

const SubHeader = (props) => {
  return (
    <section className="py-6 bg-primary">
      <Container>
        <h1 className="text-white">{props.title}</h1>
        <div className="p-0 d-flex list-unstyled ">
          <BreadcrumbItem>
            <a href="/" className="text-white">
              Home
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem className="text-white" active>
            {props.title}
          </BreadcrumbItem>
        </div>
      </Container>
    </section>
  );
};

export default SubHeader;
