import { useAuth0 } from "@auth0/auth0-react"
import { Icon } from "@iconify/react/dist/iconify.js"
import { IconButton, Tooltip } from "@mui/material"
import React from "react"
import { Bookmark, Calendar, Clock, MapPin, Star } from "react-feather"
import { Highlight } from "react-instantsearch-dom"
import { useNavigate } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import styled from "styled-components"
import { getDateTime } from "../../utils/helper"

const defaultEventBannerImg = "https://s3.us-east-1.amazonaws.com/assets.mycoa.io/event-calendar-placeholder+(1).png"

const getURL = (event) => {
  const category1 = event?.categories_slugs?.[0]?.slug || ""
  const category2 = event?.categories_slugs?.[1]?.slug || ""
  const eventSlug = event?.slug || ""

  const url = `/${category1}/${category2}/${eventSlug}`

  console.log(url)

  return url
}


const EventItem = ({ event, favoriteEvents, toggleFavoriteEvent, setShowTeaser }) => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()
  const eventData = event?.event_data || {}
  const isFavorite = favoriteEvents.includes(event._id)

  const [isFav, setIsFav] = React.useState(isFavorite)

  const eventLocation = `${eventData.venue}, ${eventData.address1}, ${
    eventData?.address2 ? `${eventData?.address2}, ` : ""
  }${eventData.city}, ${eventData.state}, ${eventData.zip_code}, ${eventData.country}`

  const downloadICS = () => {
    const eventURL = eventData.event_url

    if (!eventURL) {
      console.error("No event URL provided")
      return
    }

    // Open the event URL in a new tab
    window.open(eventURL, "_blank")
  }

  const startTime = getDateTime(eventData.start_date)
  const endTime = getDateTime(eventData.end_date)
  const isSameDay = startTime.toDateString() === endTime.toDateString()

  // April 25 - April 27, 2025
  const eventDate = isSameDay
    ? startTime.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" })
    : startTime.toLocaleString("default", { month: "long", day: "numeric" }) +
      " - " +
      endTime.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" })

  const sanitizedSnippetHead = event?._snippetResult?.title
    ? event._snippetResult.title.value.replace(/&amp;/g, " ")
    : ""

  const goToEvent = () => {
    navigate(getURL(event))
  }

  const handleToggleFavorite = () => {
    if (!isAuthenticated) {
      setShowTeaser(true)
      document.querySelector("body").classList.remove("overflow-hidden")
      return
    }

    setIsFav(!isFav) // Instant UI update
    toggleFavoriteEvent(event._id) // API call happens in the background
  }

  return (
    <EventItemContainer className="hit d-block">
      <Row>
        <Col md="3" className="mb-3 mb-md-0">
          <div className="image-wrapper" onClick={() => goToEvent()}>
            {event.feature_image && event.feature_image !== "null" && event.feature_image !== defaultEventBannerImg ? (
              <img src={event.feature_image} alt="featured" width={"100%"} />
            ) : (
              <img src="/images/events-placeholder.jpg" alt="featured" width={"100%"} />
            )}
            <div className="event-date">
              <span>
                {new Date(startTime).toLocaleString("en-GB", {
                  day: "2-digit",
                })}
              </span>
              <span>
                {new Date(startTime)
                  .toLocaleString("en-GB", {
                    month: "short",
                  })
                  .toUpperCase()}
              </span>
            </div>
          </div>
        </Col>
        <Col md="9" className="">
          <div className="d-flex align-items-start flex-wrap flex-md-nowrap " style={{ gap: "15px 20px" }}>
            <div className="event-body flex-grow-1 hit-content">
              <a className="event-title d-block" href={getURL(event)}>
                <Highlight
                  attribute="title"
                  hit={{ ...event, _snippetResult: { body: { title: sanitizedSnippetHead } } }}
                />
              </a>
              <div className="event-meta-desc">
                <span>
                  <Icon icon={"mdi:calendar-month-outline"} fontSize={"18px"} />{" "}
                  {/* {new Date(startTime).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })} */}
                  {eventDate}
                </span>

                <span className="event-time">
                  <Icon icon="iconamoon:clock" fontSize={"18px"} />{" "}
                  {new Date(startTime)
                    .toLocaleTimeString("en-GB", {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })
                    .replace(/am|pm/i, (match) => match.toUpperCase())}{" "}
                  -{" "}
                  {new Date(endTime)
                    .toLocaleTimeString("en-GB", {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })
                    .replace(/am|pm/i, (match) => match.toUpperCase())}
                </span>
                <span style={{ cursor: "pointer" }}>
                  <Tooltip title={eventLocation} placement="top" arrow>
                    <Icon icon="tdesign:map-location" fontSize={"18px"} /> {eventData?.city}
                  </Tooltip>
                </span>
                <span onClick={downloadICS} style={{ cursor: "pointer" }}>
                  <Icon icon="mdi:calendar-plus-outline" fontSize={"18px"} />
                  Add to Calendar
                </span>
              </div>
            </div>
            <div
              className="action-buttons d-flex align-items-center flex-wrap"
              style={{ gap: "15px", minWidth: "240px" }}
            >
              <Tooltip title="Bookmark" placement="top" arrow>
                <IconButton
                  onClick={handleToggleFavorite}
                  // color={isFav ? "primary" : "default"}
                  className={isFav ? "bookmarked" : ""}
                  sx={{
                    "&.bookmarked": {
                      svg: {
                        fill: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.main,
                      },
                    },
                  }}
                >
                  <Star />
                </IconButton>
              </Tooltip>
              <button
                onClick={() => goToEvent()}
                className="btn btn-outline-primary btn-sm text-uppercase"
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                Learn More
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </EventItemContainer>
  )
}

export default EventItem

export const EventItemContainer = styled.article`
  margin-bottom: 25px;
  padding-bottom: 25px;

  &:hover {
    .image-wrapper {
      img {
        transform: scale(1.02);
      }
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #eee;
  }

  .image-wrapper {
    position: relative;
    cursor: pointer;

    img {
      object-fit: cover;
      aspect-ratio: 16/9;
      object-position: center;
      border-radius: 15px;
      min-height: 100px;
      transition: transform 300ms;
    }

    .event-date {
      position: absolute;
      top: 15px;
      left: 15px;
      background-color: #fff;
      padding: 5px 15px;
      border-radius: 10px;
      span {
        display: block;
        text-align: center;

        &:first-child {
          font-weight: 600;
          font-size: 30px;
          line-height: 1;
        }

        &:last-child {
          color: #727272;
          font-weight: 500;
        }
      }
    }
  }

  .event-body {
    .event-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.4;
      color: var(--primary);
      transition: color 300ms;
      &:hover {
        color: var(--link);
      }

      @media (max-width: 576px) {
        font-size: 1.2rem;
      }
    }

    .event-meta-desc {
      display: flex;
      flex-wrap: wrap;
      gap: 5px 20px;
      color: #727272;
      flex-direction: column;

      span {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        font-size: 15px;
        transition: color 300ms;
        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  .action-buttons {
    button {
      font-size: 18px;
      padding-block: 7px;
      border-width: 1.5px;
    }
  }
`
