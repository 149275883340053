// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid2,
  IconButton,
  Input,
  InputLabel,
  lighten,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import placeholderImage from "../../../../assets/images/comment-letters-placeholder.jpg";
import moment from "moment";
import { swalalertWithMsg, valSwalFailure, valSwalSuccess } from "../../../../dev-config/global";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const SinglePageTemplate = (props) => {
  const {
    post,
    relatedPosts,
    isAuthenticated,
    publicView,
    relatedResources,
    featuredResources,
    categoryResources,
    userResourceFavourite,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [bookmark, setBookmark] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    setBookmark(userResourceFavourite);
  }, [userResourceFavourite]);
  const { user } = useAuth0();

  const handleResourceFavourite = (value) => {
    setBookmark(value);
    swalalertWithMsg(true, "Please wait!");
    axios
      .post(`${process.env.REACT_APP_API_URL}/resource/make-favourite`, {
        resource_id: post?._id,
        user_id: user?.sub.replace("auth0|", ""),
        type: value ? "Add" : "Remove",
      })
      .then((response) => {
        valSwalSuccess("Success", "Updated successfully!");
      })
      .catch((err) => {
        valSwalFailure("Error", "Something went wrong, please try again!");
        setBookmark(!value);
      });
  };

  return (
    <PageWrapper sx={{ py: 6 }}>
      <div className="article-wrapper px-3">
        <div className="article-left"></div>
        <article className="article-content">
          <header>
            <h1> {post?.title} </h1>
            <Typography
              className="desc"
              sx={{ "em p": { fontWeight: "600 !important", lineHeight: "1.7 !important" } }}
            >
              <em>{parse(`${post?.short_description}`)}</em>
            </Typography>
            <hr />
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"5px 15px"}
              flexWrap={"wrap"}
              pt={1}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"5px 15px"}
                flexWrap={"wrap"}
                sx={{ mr: "auto" }}
              >
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center", gap: "3px" }}
                  fontSize={"14px"}
                  color={"grey"}
                  fontWeight={600}
                >
                  <Icon icon="bx:calendar" />
                  {post?.date ? moment(post?.date).format("MMM DD, YYYY") : ""}
                </Typography>
                <Typography
                  component="span"
                  sx={{ display: "flex", alignItems: "center", gap: "3px" }}
                  fontSize={"14px"}
                  color={"grey"}
                  fontWeight={600}
                >
                  <Icon icon="tabler:category" />
                  {post?.categories_slugs[0].name} : {post?.categories_slugs[1].name}
                </Typography>
              </Stack>

              <GreyButton
                size="small"
                variant="outlined"
                hidden={isAuthenticated ? false : true}
                className={bookmark ? "bookmarked" : ""}
                onClick={() => handleResourceFavourite(!bookmark)}
                sx={{
                  borderRadius: "100px",
                  minWidth: "unset",
                  maxWidth: "36px",
                  height: "36px",
                  p: 0,
                  width: "100%",
                }}
              >
                <Icon icon="bx:bookmark" width={22} />
              </GreyButton>
              <GreyButton
                id="share-article"
                size="small"
                variant="outlined"
                sx={{
                  borderRadius: "100px",
                  minWidth: "unset",
                  maxWidth: "36px",
                  height: "36px",
                  p: 0,
                  width: "100%",
                }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Icon icon="bx:share-alt" width={22} />
              </GreyButton>
              <Button
                hidden={!post?.file || post?.file == "undefined"}
                size="sm"
                variant="contained"
                component={"a"}
                href={post?.file}
                target="_blank"
                color="primary"
                sx={{ borderRadius: "100px", "&:hover": { color: "#fff" } }}
                startIcon={<Icon icon="bx:download" fontSize={"18px"} />}
              >
                Download
              </Button>
              <Menu
                id="share-article"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{ li: { minHeight: "unset" } }}
              >
                {/* Facebook Share */}
                <MenuItem
                  onClick={() => {
                    window.open(
                      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
                      "_blank"
                    );
                    handleClose();
                  }}
                >
                  <ListItemIcon size="small">
                    <Icon icon="devicon-plain:facebook" width={"24px"} />
                  </ListItemIcon>
                  <ListItemText sx={{ ".MuiTypography-root": { fontSize: "14px" } }}>
                    Facebook
                  </ListItemText>
                </MenuItem>

                {/* Twitter Share */}
                <MenuItem
                  onClick={() => {
                    window.open(
                      `https://twitter.com/intent/tweet?url=${window.location.href}`,
                      "_blank"
                    );
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Icon icon="fa6-brands:square-x-twitter" width={"23px"} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ ".MuiTypography-root": { fontSize: "14px" } }}
                    onClick={() => {
                      window.open(
                        `https://twitter.com/intent/tweet?url=${window.location.href}`,
                        "_blank"
                      );
                    }}
                  >
                    Twitter
                  </ListItemText>
                </MenuItem>

                {/* Instagram (redirect to Instagram) */}
                {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Icon icon="f7:logo-instagram" width={"28px"} />
          </ListItemIcon>
          <ListItemText
            sx={{ ".MuiTypography-root": { fontSize: "14px" } }}
            onClick={() => {
              window.open(`https://instagram.com?url=${window.location.href}`, '_blank');
            }}
          >
            Instagram
          </ListItemText>
        </MenuItem> */}

                <MenuItem
                  onClick={() => {
                    window.open(
                      `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
                      "_blank"
                    );
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Icon icon="fa6-brands:linkedin" width={"23px"} />
                  </ListItemIcon>
                  <ListItemText sx={{ ".MuiTypography-root": { fontSize: "14px" } }}>
                    LinkedIn
                  </ListItemText>
                </MenuItem>
              </Menu>
            </Stack>
          </header>
          {isAuthenticated || publicView == true ? (
            <>
              <Box className="article-body" sx={{ marginTop: "30px" }}>
                <img
                  hidden={!post?.show_feature_image}
                  alt="featured"
                  className="featured-image"
                  src={
                    post?.feature_image && post?.feature_image != "null"
                      ? post?.feature_image
                      : placeholderImage
                  }
                  style={{ marginBottom: "35px" }}
                />
                {parse(`${post?.body}`)}
                <Divider sx={{ mt: 2, mb: 2.5 }} />
                <Box>
                  <small>See More: </small>
                  <small style={{ fontStyle: "italic" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`/${post?.categories_slugs?.[0]?.slug}/${post?.categories_slugs?.[1]?.slug}`}
                    >
                      {post?.categories_slugs?.[1]?.name}
                    </a>
                  </small>

                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"5px 15px"}
                    flexWrap={"wrap"}
                    mt={2}
                  >
                    
                    <GreyButton
                      size="small"
                      variant="outlined"
                      hidden={isAuthenticated ? false : true}
                      className={bookmark ? "bookmarked" : ""}
                      onClick={() => handleResourceFavourite(!bookmark)}
                      sx={{
                        borderRadius: "100px",
                        minWidth: "unset",
                        maxWidth: "36px",
                        height: "36px",
                        p: 0,
                        width: "100%",
                      }}
                    >
                      <Icon icon="bx:bookmark" width={22} />
                    </GreyButton>

                    {/* <GreyButton
                      hidden={!post?.file || post?.file == "undefined"}
                      size="small"
                      variant="outlined"
                      sx={{
                        borderRadius: "100px",
                        minWidth: "unset",
                        maxWidth: "36px",
                        height: "36px",
                        padding: 0,
                        width: "100%",
                      }}
                      component={"a"}
                      href={post?.file}
                      target="_blank"
                    >
                      <Icon icon="bx:download" width={22} />
                    </GreyButton> */}
                    <GreyButton
                      id="share-article"
                      size="small"
                      variant="outlined"
                      sx={{
                        borderRadius: "100px",
                        minWidth: "unset",
                        maxWidth: "36px",
                        height: "36px",
                        p: 0,
                        width: "100%",
                      }}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <Icon icon="bx:share-alt" width={22} />
                    </GreyButton>
                    <Button
                      hidden={!post?.file || post?.file == "undefined"}
                      size="sm"
                      variant="contained"
                      component={"a"}
                      href={post?.file}
                      target="_blank"
                      color="primary"
                      sx={{ borderRadius: "100px", "&:hover": { color: "#fff" } }}
                      startIcon={<Icon icon="bx:download" fontSize={"18px"} />}
                    >
                      Download
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
        </article>

        <Box className="article-right"></Box>
      </div>
      {/*End: article wrapper */}

      <Box
        sx={{
          backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.9),
          mt: 6,
          pt: 6,
          pb: 6,
        }}
      >
        <Container>
          <Grid2 container spacing={4} alignItems={"center"}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Card
                elevation={0}
                sx={{
                  display: "flex",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  gap: [0, 2],
                  alignItems: "center",
                  mt: 1,
                  mb: 3,
                  backgroundColor: "transparent",
                }}
              >
                {/* <CardMedia
                  sx={{
                    maxWidth: {
                      //   xs: "100%",
                      //   sm: "100px",
                      img: {
                        width: "84px",
                        height: "84px",
                        objectFit: "cover",
                      },
                    },
                  }}
                >
                  <img src="/coa.svg" alt="featured" />
                </CardMedia> */}
                <CardContent sx={{ p: 0, pb: "0 !important" }}>
                  <Typography fontWeight={800} component={"h3"} fontSize={"24px"}>
                  Stay Connected! 
                  </Typography>
                  <Typography fontSize={"1.0625rem"} fontWeight={500}>
                  Subscribe to get the latest COA news and updates direct to your inbox.
                  </Typography>
                </CardContent>
              </Card>

              <form>
                <Grid2
                  container
                  spacing={2}
                  sx={{ input: { backgroundColor: "#fff", borderRadius: 1 } }}
                >
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField
                      placeholder="First Name"
                      fullWidth
                      name="first-name"
                      id="first-name"
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField fullWidth placeholder="Last Name" name="last-name" id="last-name" />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField placeholder="Email" fullWidth name="email" id="email" />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField fullWidth placeholder="Zip Code" name="zip-code" id="zip-code" />
                  </Grid2>
                </Grid2>
                {/* <Alert variant="filled" severity="success" sx={{ mt: 2 }}>
                  Form submitted successfully
                </Alert> */}
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ px: 3, py: 1.5, textTransform: "uppercase", mt: 2 }}
                >
                  Submit
                </Button>
              </form>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img src="/images/stay-connected.jpg" alt="stay connected" width={"100%"} />
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      {/* Begin: Related */}
      <Box sx={{ pt: 6 }}>
        <Container>
          <div hidden={!post?.related_section || relatedResources?.length == 0}>
            <Typography component={"h5"} fontWeight={600} mb={3}>
              Related
              <a
                href={
                  "/related-articles/" + post?.categories_slugs?.[1]?.slug
                }
                target="_blank"
              >
                {" "}
                [More]
              </a>
            </Typography>
            <Grid2 container flexWrap={"wrap"} spacing={3}>
              {relatedResources?.map((item, index) => {
                const subCategoryUrl =
                  "/" + item?.categories_slugs?.[0]?.slug + "/" + item?.categories_slugs?.[1]?.slug;
                return (
                  <Grid2 key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <PostCard
                      href={subCategoryUrl + "/" + item?.slug}
                      image={item.feature_image}
                      title={item?.title}
                    />
                  </Grid2>
                );
              })}
            </Grid2>
          </div>

          {/* Recent */}

          <div hidden={!post?.featured_section || featuredResources?.length == 0}>
            <Divider sx={{ mt: 4, mb: 3.5 }} />

            <Typography component={"h5"} fontWeight={600} mb={3}>
              Featured from COA
              <a href="/feature-articles" target="_blank">
                {" "}
                [More]
              </a>
            </Typography>

            <Grid2 container flexWrap={"wrap"} spacing={3}>
              {featuredResources.map((item, index) => {
                const subCategoryUrl =
                  "/" + item?.categories_slugs?.[0]?.slug + "/" + item?.categories_slugs?.[1]?.slug;
                return (
                  <Grid2 key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <PostCard
                      href={subCategoryUrl + "/" + item?.slug}
                      image={item.feature_image}
                      title={item?.title}
                    />
                  </Grid2>
                );
              })}
            </Grid2>
          </div>

          <div hidden={!post?.category_section || categoryResources?.length == 0}>
            <Divider sx={{ mt: 4, mb: 3.5 }} />
            <Typography component={"h5"} fontWeight={600} mb={3}>
             {post?.categories_slugs[1].name} 
             <a
                href={
                  "/"+post?.categories_slugs?.[0]?.slug+ "/" + post?.categories_slugs?.[1]?.slug
                }
                target="_blank"
              >
                {" "}
                [More]
              </a>
            </Typography>

            <Grid2 container flexWrap={"wrap"} spacing={3}>
              {categoryResources.map((item, index) => {
                const subCategoryUrl =
                  "/" + item?.categories_slugs?.[0]?.slug + "/" + item?.categories_slugs?.[1]?.slug;
                return (
                  <Grid2 key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <PostCard
                      href={subCategoryUrl + "/" + item?.slug}
                      image={item.feature_image}
                      title={item?.title}
                    />
                  </Grid2>
                );
              })}
            </Grid2>
          </div>
        </Container>
      </Box>
    </PageWrapper>
  );
};

export const PostCard = ({ href, image, title }) => {
  return (
    <Card
      elevation={0}
      component={"a"}
      href={href}
      target="_blank"
      sx={{
        display: "block",
        transition: "300ms ease",
        "&:hover": { transform: "translateY(-3px)" },
      }}
    >
      <CardMedia
        component={"img"}
        image={image ? image : placeholderImage}
        alt="featured"
        sx={{ "&": { aspectRatio: "16/9" } }}
      />
      <CardContent sx={{ p: "0 !important" }}>
        <Typography mt={2} fontWeight={500} className="three-line-desc" sx={{ "& p": { mb: 0 } }}>
          {title && parse(title)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SinglePageTemplate;

const PageWrapper = styled(Box)(({ theme }) => ({
  background: "linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 15%);",
  ".article-wrapper": {
    display: "grid",
    gridTemplateColumns: "1fr minmax(auto, 800px) 1fr",
    gridTemplateRows: "1fr 400px 1fr",
    gap: "40px",

    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto 800px auto",
      gridTemplateRows: "auto",
      justifyContent: "center",
    },

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gap: "10px",

      ".article-left, .article-right": {
        display: "none",
      },
    },

    [theme.breakpoints.up("lg")]: {
      ".article-left": {
        gridRow: "1/4",
      },
      ".article-content": {
        gridRow: "1/4",
      },
      ".article-right": {
        gridRow: "1/4",
      },
    },
  },

  ".three-line-desc p, .three-line-desc": {
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  },

  ".article-content": {
    "--articleBodyFontsize": "1.0625em",
    "--articleBodyLineHeight": "1.9",
    "--articleHeadingLineHeight": "1.4",

    "@media(max-width: 576px)": {
      "--articleBodyFontsize": "1rem",
      "--articleBodyLineHeight": "1.8",
    },

    ".featured-image": {
      aspectRatio: "4/2",
      objectFit: "cover",
    },

    // Image Styling
    img: {
      width: "100%",
      display: "block",
      marginBlock: "30px",
      borderRadius: "3px",
    },

    // Headings
    h1: {
      fontSize: "1.9rem",
      marginBottom: "25px",
      fontWeight: "700",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h2: {
      fontSize: "1.7rem",
      marginTop: "25px",
      marginBottom: "15px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h3: {
      fontSize: "1.5rem",
      marginTop: "25px",
      marginBottom: "12px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h4: {
      fontSize: "1.3rem",
      marginTop: "25px",
      marginBottom: "10px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h5: {
      fontSize: "1.1rem",
      marginTop: "25px",
      marginBottom: "8px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },
    h6: {
      fontSize: "1rem",
      marginTop: "25px",
      marginBottom: "6px",
      fontWeight: "600",
      lineHeight: "var(--articleHeadingLineHeight)",
    },

    p: {
      fontSize: "var(--articleBodyFontsize)",
      // fontWeight: 500,
      lineHeight: "var(--articleBodyLineHeight)",
      marginBottom: "25px",
    },

    "a:not(.MuiButtonBase-root)": {
      wordBreak: "break-all",
      fontSize: "var(--articleBodyFontsize)",
      lineHeight: "var(--articleBodyLineHeight)",
    },

    em: {
      fontWeight: 600,
    },

    small: {
      // fontWeight: 500,
    },

    li: {
      fontSize: "var(--articleBodyFontsize)",
      lineHeight: "var(--articleBodyLineHeight)",
      // fontWeight: 500,
    },

    // Blockquote
    blockquote: {
      fontStyle: "italic",
      borderLeft: "4px solid var(--primary)",
      lineHeight: "var(--articleBodyLineHeight)",
      paddingLeft: "15px",
      margin: "25px 0",
      color: "#555",
      fontSize: "var(--articleBodyFontsize)",
      // fontWeight: 500,
    },

    // Tables
    table: {
      width: "100%",
      borderCollapse: "collapse",
      margin: "25px 0",
    },
    "table, th, td": {
      border: "1px solid #ddd",
    },
    th: {
      backgroundColor: "#f4f4f4",
      padding: "10px",
      textAlign: "left",
      fontSize: "var(--articleBodyFontsize)",
      lineHeight: "var(--articleBodyLineHeight)",
      fontWeight: 600,
    },
    td: {
      padding: "10px",
      textAlign: "left",
      fontSize: "var(--articleBodyFontsize)",
      // fontWeight: 500,
      lineHeight: "var(--articleBodyLineHeight)",
    },

    // Embedded Videos
    iframe: {
      display: "block",
      width: "100%",
      height: "350px",
      margin: "25px 0",
    },
  },
}));

const GreyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[700], // Text color
  borderColor: theme.palette.grey[300], // Border color

  "&:hover": {
    backgroundColor: theme.palette.grey[50], // Hover background
    borderColor: theme.palette.grey[400],
  },

  "&.bookmarked": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));
